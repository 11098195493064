import React from "react";

import { Row, Col, Card } from "antd";

import Profile from "./Profile";
import InquirieListTable from "./InquirieListTable";

const TabContentInqurieList = ({userData}) => {
  console.log(userData);
  return (
    <Row gutter={20}>
      <Col span={6}>
      <Card style={{ height: 600 }} className="ScrollY">
          <Profile userData={userData===null ? "" : userData} url={userData===null ? "" : userData.profile_pic} />
        </Card>
      </Col>
      <Col span={18}>
        <Card>
        <InquirieListTable/>
        </Card>
      </Col>
    </Row>
  );
};

export default TabContentInqurieList;
