import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Space , Radio , Select, Checkbox, Table, Button, Tag, Modal } from "antd";
import Actions from "./Actions";
import { WhatsappIcon } from "assets/svg/icon";
import { useSelector } from "react-redux";
import { useAxiosRequest } from "hooks/axios-hook";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { camelCase } from "utils/CamelCase";
import { conversion } from "components/ConversionOfId/conversion";

const columns = [
  {
    title: "Invoice Id ",
    dataIndex: "invoice_id",
  },
  {
    title: "Payment Id",
    dataIndex: "id",
    render:(id)=>{
      return conversion(id,"payment",null)
    }
    // defaultSortOrder: 'descend',
    // sorter: (a, b) => a.item - b.item,
  },
  {
    title: "Item",
    dataIndex: "name",
  },
  {
    title: "Payment Date",
    dataIndex: "payment_date",
  },
  {
    title: "Amount",
    dataIndex: "final_price",
  },
  {
    title: "Payment Type",
    dataIndex: "payment_method",
  },

  {
    title: "Status",
    dataIndex: "status",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.satus - b.status,

    render: (status) => {
      return (
        <span>
          <Tag color="green" key={status}>
            {status}
          </Tag>
        </span>
      );
    },
  },

  {
    title: "Action",
    dataIndex: "",
    key: "x",
    render: () => <Actions />,
  },
];

const data = [
  {
    key: 1,
    Invoice_Id: "HCI123-INV",
    Payment_Id: "HCI123-PAY",
    Item: "XYZ Hand Bag",
    Payment_Date: "19/08/2022",
    Amount: "S$123.00",
    Payment_Type: "PayPal",
    status: "paid",
  },
];
for (let i = 2; i <= 7; i++) {
  data.push({
    key: i,
    Invoice_Id: `HCI123${i}-INV`,
    Payment_Id: `HCI123${i}-PAY`,
    Item: "XYZ Hand Bag",
    Payment_Date: "19/08/2022",
    Amount: "S$123.00",
    Payment_Type: "CASH",
    status: "INVALID",
  });
}

const InquiriesAndServices = ({paymentType, status}) => {
  const [payments, setPayments] = useState([]);
  const [filter, setFilter] = useState({});

  const { primaryId, secondaryId } = useSelector(
    (state) => state.accountDetails
  );
  const columns = [
    {
      title: "Invoice Id ",
      dataIndex: "invoice_id",
      render: (data, row) => {
        const firstInvoice = row.invoices[0];
        console.log("firstInvoice", firstInvoice);
        return <> {conversion(firstInvoice.invoice_id,"invoice",row.service_type)}</>;
      },
    },
    {
      title: "Payment Id",
      dataIndex: "id",
      render:(id,data)=>{
        return conversion(id,"payment",data.service_type)
      }
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.item - b.item,
    },
    {
      title: "Item",
      dataIndex: "name",
      render: (data, row) => {  
        const firstItem = row.items[0];
        console.log("firstItem", firstItem);
        return <> {firstItem?.name}</>;
      },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
    },
    {
      title: "Amount",
      dataIndex: "amount_paid",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_method",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, row) => {
        const firstInvoice = row.invoices[0];
        return (
          <span>
            {firstInvoice?.invoice_status == 'paid' ? <Tag color="green" key={firstInvoice?.invoice_status}>
              {camelCase(firstInvoice?.invoice_status)}
            </Tag> : <Tag color="purple" key={firstInvoice?.invoice_status}>
              {camelCase(firstInvoice?.invoice_status)}
            </Tag>}
          </span>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record) =>{
        console.log(record)
        return (
        <Actions id={record.invoices[0].invoice_id} type="Finance" />
        )}
    },
  ];
  const { isLoading, error, sendRequest, clearError } = useAxiosRequest();

  const history = useHistory();

  useEffect(() => {
    if (!primaryId) {
      Modal.error({
        title: "ERROR",
        content: "Invalid Primary Id",
        onOk() {
          history("account-list");
        },
      });
      return;
    }
    const fetchPayments = async () => {
      let filters = {status:status,payment_type:paymentType};

      filters = JSON.stringify(filters);

      let res = await sendRequest(
        `/customers/get-customer-finance/${primaryId}?filters=${filters}`,
        "post",
        {}
      );
      
      console.log("res", res);

      if (secondaryId) {
        const res2 = await sendRequest(
          `/customers/get-customer-finance/${secondaryId}`,
          "post",
          {}
        );

        res = [...res, ...res2];
      }

      console.log("res", res);
      // setCoData(res);
      setPayments(res);
    };
    fetchPayments();
  }, [history, primaryId, secondaryId, sendRequest, paymentType, status]);

  if (error) {
    console.log(error);
    clearError();
    // Modal.error({
    //   title: "ERROR",
    //   content: error,
    //   onOk() {
    //     history.push("account-list");
    //   },
    // });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectChangeHandler = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: selectChangeHandler,
  };

  const tablePayments = payments.map((pay, i) => {
    return {
      key: i,
      ...pay,
      payment_date: moment(pay.payment_date).format("DD/MM/YYYY"),
    };
  });
  return (
    <div>
      <Table
        pagination={{
          pageSize: 10,
          total: tablePayments?.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        
        columns={columns}
        dataSource={tablePayments}
        className="w-100"
      />
    </div>
  );
};

export default InquiriesAndServices;

const Filter = (props) => {
  const [itemTypes, setItemTypes] = useState([]);

  const getItemTyes = async () => {
    try {
      const res = await axios.get(`/customer-app/get-all-Item-types`);
      if (!res.data.success) {
        throw new Error(res.data.data);
      }
      console.log(res.data);
      setItemTypes([...res.data.data.map((el) => ({ label: el.item_category, value: el.id }))]);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getItemTyes();
  }, []);

  let menu = (
    <Menu mode="horizontal">
      <Menu.SubMenu key="item1" title="Status">
        <Menu.Item key="subitem1">
          <Checkbox onChange={(e) => {
            const newFilter = {...props.filter , status : e.target.checked ? [] : null};
            delete newFilter.status;
            props.setFilter(newFilter);
          }}>All</Checkbox>
        </Menu.Item>
        <Menu.Item key="subitem2">
          <Checkbox onChange={(e) =>{
            const oldStatus = props.filter.status || [];
            if(e.target.checked){
              oldStatus.push('paid');
            }
            else{
              oldStatus.splice(oldStatus.indexOf('paid') , 1);
            }
            props.setFilter({...props.filter , status : [...new Set(oldStatus)]});
          }}>Paid</Checkbox>
        </Menu.Item>
        <Menu.Item key="subitem3">
          <Checkbox onChange={(e) => {
            const oldStatus = props.filter.status || [];
            if(e.target.checked){
              oldStatus.push('unpaid');
            }
            else{
              oldStatus.splice(oldStatus.indexOf('unpaid') , 1);
            }
            props.setFilter({...props.filter , status : [...new Set(oldStatus)]});
          }}>Unpaid</Checkbox>
        </Menu.Item>
        <Menu.Item key="subitem4">
          <Checkbox onChange={(e) => {
            const oldStatus = props.filter.status || [];
            if(e.target.checked){
              oldStatus.push('partial');
            }
            else{
              oldStatus.splice(oldStatus.indexOf('partial') , 1);
            }
            props.setFilter({...props.filter , status : [...new Set(oldStatus)]});
          }}>Partial</Checkbox>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="item2" title="Payment Type">
        <Menu.Item key="subitem1">
          <Checkbox onChange={(e) => {
            props.setFilter({...props.filter , payment_method : e.target.checked ? [] : null});
          }}>All</Checkbox>
        </Menu.Item>
        
        <Menu.Item key="subitem3">
          <Checkbox onChange={(e) => {
            const oldStatus = props.filter.payment_method || [];
            if(e.target.checked){
              oldStatus.push('paylah');
            }
            else{
              oldStatus.splice(oldStatus.indexOf('paylah') , 1);
            }
            props.setFilter({...props.filter , payment_method : [...new Set(oldStatus)]});
          }}>Paylah</Checkbox>
        </Menu.Item>
        <Menu.Item key="subitem4">
          
            <Checkbox onChange={(e) => {
              const oldStatus = props.filter.payment_method || [];
              if(e.target.checked){
                oldStatus.push('paynow'); 
              }
              else{
                oldStatus.splice(oldStatus.indexOf('paynow') , 1);
              }
              props.setFilter({...props.filter , payment_method : [...new Set(oldStatus)]});
            }}>Paynow</Checkbox>
        </Menu.Item>
        {/* <Menu.Item key="subitem5">
          <Checkbox>paynow</Checkbox>
        </Menu.Item> */}
        <Menu.Item key="subitem6">
          
            <Checkbox onChange={(e) => {
              const oldStatus = props.filter.payment_method || [];
              if(e.target.checked){
                oldStatus.push('cash on pickup');
              }
              else{
                oldStatus.splice(oldStatus.indexOf('cash on pickup') , 1);
              }
              props.setFilter({...props.filter , payment_method : [...new Set(oldStatus)]});
            }}>Cash on Pickup</Checkbox>

        </Menu.Item>
        <Menu.Item key="subitem7">
          
          <Checkbox onChange={(e) => {
            const oldStatus = props.filter.payment_method || [];
            if(e.target.checked){
              oldStatus.push('cheque on pickup');
            }
            else{
              oldStatus.splice(oldStatus.indexOf('cheque on pickup') , 1);
            }
            props.setFilter({...props.filter , payment_method : [...new Set(oldStatus)]});
          }}>Cheque on Pickup</Checkbox>

      </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="item3" title="Item Type">
        <Menu.Item key="subitem1">
          <Checkbox
          value={
            props.filter.item_type ? props.filter.item_type.includes(null) : false
          }
          onChange={(e) => {
            props.setFilter({...props.filter , item_type : e.target.checked ? [] : null});
          }}>All</Checkbox>
        </Menu.Item>
        {itemTypes.map((itemType) => (
          <Menu.Item key={itemType.value}>
            <Checkbox value={
              props.filter.item_type ? props.filter.item_type.includes(itemType.value) : false
            } onChange={(e) => {
              const oldStatus = props.filter.item_type || [];
              if(e.target.checked){
                oldStatus.push(itemType.value);
              }
              else{
                oldStatus.splice(oldStatus.indexOf(itemType.value) , 1);
              }
              props.setFilter({...props.filter , item_type : [...new Set(oldStatus)]});
            }}>{itemType.label}</Checkbox>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    </Menu>
  );


  if(props.menu){
    menu = props.menu;
  }

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      {props.children}
    </Dropdown>
    
  );
};
