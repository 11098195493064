import React, { useState, useEffect, forwardRef } from "react";
import { Typography, Select, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { WhatsappIcon, MailIcon, MobileIcon } from "assets/svg/icon";
import { conversion } from "components/ConversionOfId/conversion";

const { Title } = Typography;

const Inquiries = forwardRef(({
  inquiries, inboxType, setOneUser, setCustomerId, pageSize,
  pageNumber, totalSize, setPageNumber
}, ref) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Add incoming inquiries data to the array if page number increases
    if (pageNumber > data.length / pageSize) {
      // Filter out items that are already present in the data array
      const newInquiries = inquiries.filter(inquiry => !data.find(item => item.id === inquiry.id));
      setData(prevData => [...prevData, ...newInquiries]);
    }
  }, [inquiries, pageNumber, pageSize]);

  const handleScroll = (e) => {
    const currentScrollPos = e.target.scrollTop;
    const scrollableDiv = document.getElementById('scrollableDiv');

    if (currentScrollPos + e.target.clientHeight >= e.target.scrollHeight - 5 && !isLoading && data.length < totalSize) {
      setIsLoading(true);
      setTimeout(() => {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
        setIsLoading(false); // Reset loading state after changing page number

        // Move the scrollbar upward
        const distanceFromBottom = scrollableDiv.scrollHeight - currentScrollPos;
        const newScrollPos = scrollableDiv.scrollHeight - (distanceFromBottom * 0.7); // Around 70% above
        scrollableDiv.scrollTop = newScrollPos;
      }, 1000); // Delay of 1 second (1000 milliseconds)
    }
  };

  return (
    <div ref={ref} style={{ overflowY: "auto", maxHeight: "530px" }} onScroll={handleScroll} id="scrollableDiv">
      <div className="d-flex justify-content-between">
        <div className="d-flex" style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
          <Title level={3}>Chats</Title>
          <div>
            <Select style={{ width: "150px" }} defaultValue={"Whatsapp"}>
              <Select.Option value="All">All Channels</Select.Option>
              <Select.Option value="Whatsapp">Whatsapp</Select.Option>
              <Select.Option value="facebook">Facebook</Select.Option>
              <Select.Option value="Phone_call">Phone Call</Select.Option>
              <Select.Option value="Email">Email</Select.Option>
              <Select.Option value="store_visit">Store Visit</Select.Option>
            </Select>
          </div>
        </div>
        <div className="d-flex">
          {/* Optional buttons */}
        </div>
      </div>

      <div>
        <div id="scrollableDiv" className="mt-4">
          {data.map((item, i) => (
            <div key={i} style={{ marginRight: "20px", marginBottom: "20px" }}>
              <div
                className="d-flex justify-content-between w-100 pointer"
                style={{ cursor: "pointer" }}
                onClick={() => setCustomerId(item)}
              >
                <div className="d-flex">
                  <div style={{ width: "4rem", marginRight: "1rem" }}>
                    {item.customer_profile_pic ? (
                      <img
                        style={{ maxWidth: "100%", borderRadius: "50%", height: "50px", width: "50px", objectFit: "cover" }}
                        src={item.customer_profile_pic}
                        alt="..."
                      />
                    ) : (
                      <div className="p-2"><UserOutlined /></div>
                    )}
                  </div>
                  <div style={{ width: "55%", }}>
                    <div style={{ fontWeight: "bold" }}>
                      {item.customer_name && item.customer_name}
                    </div>
                    <br />
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-around ">
                    <Badge count={1} style={{ background: "#0099A8", margin: "10px" }} />
                    <Icon component={item.inquiry_Channel === "Whatsapp" ? WhatsappIcon : item.inquiry_Channel === "Email" ? MailIcon : MobileIcon} />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                {conversion(item.id, "inquiry", null)} {(item.inquiry_type)}
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Inquiries;
