import Icon from '@ant-design/icons';
import React from 'react'

import Button from 'antd/es/button';

import { submitTick } from 'assets/svg/icon'

const SuccessSubmit = (props) => {
  const {isEditing , id } = props;
  return (
    <div className='text-center'>
      
      <Icon component={submitTick}/>
      
      <div>
        {isEditing && <h1>Staff Edited Successfully!</h1>}
        {!isEditing && <h1>New Staff Added Successfully!</h1>}
        {!isEditing &&<p>User Id John Smith, is added in staff successfully</p>}
      </div>
    </div>
  )
}

export default SuccessSubmit