import React from "react";
// import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";
import { Menu, Modal } from "antd";

import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown/index";
import { VisibilityIcon, DeleteIcon, EditIcon } from "assets/svg/ActionsSvg";
import { Link, useHistory } from "react-router-dom";

const Actions = (props) => {
  const { onEdit, onDelete, onViewDetails, id } = props;

  const history = useHistory();

  const viewDetailsHandler = async() => {
    await onViewDetails(id);
    history.push(`account-details?id=${id.slice(3)}`);
  };
  const editHandler = () => {
    onEdit(id);
  };
  const deleteHandler = () => {
    Modal.confirm({
      title: "Are you sure delete the Customer?",
      icon: <ExclamationCircleOutlined/>,
      content: `Customer Id - ${id}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDelete(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <EllipsisDropdown
      menu={
        <Menu>
          <Menu.Item onClick={viewDetailsHandler}>
            <span className="d-flex align-items-center">
              <Icon className="mr-2" component={VisibilityIcon} />
              View Details
            </span>
          </Menu.Item>
          <Menu.Item onClick={editHandler}>
            <span className="d-flex align-items-center">
              <Icon className="mr-2" component={EditIcon} />
              Edit Details
            </span>
          </Menu.Item>
          <Menu.Item onClick={deleteHandler}>
            <span className="d-flex align-items-center">
              <Icon className="mr-2" component={DeleteIcon} />
              Delete
            </span>
          </Menu.Item>
        </Menu>
      }
    />
  );
};

export default Actions;
