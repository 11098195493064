import React from "react";
import "./Header.css";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import { Avatar, Button, Menu, Modal, Input, Radio, Space } from "antd";
import { Cross, DeleteIcon, PersonSearchIcon, PreviewRequest } from "assets/svg/ActionsSvg";
import Icon, { UserOutlined } from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { FolderOutlined } from "@ant-design/icons";
import { Successfully } from "configs/svgIcons";
// import SuccessSubmit from 'views/app-views/UserManagement/AddNewStaffV2/AsiignShift/SuccessSubmit'
import { useState } from "react";
import { Spin } from 'antd'; // if using Spin component
import { LoadingOutlined } from '@ant-design/icons';
import { ProfileIcon } from "assets/svg/icon";
import { conversion } from "components/ConversionOfId/conversion";
import { useNotification } from '../../../../../NotificationContext';


function Header({ oneUser, inboxType, customerId, closeEnquiry, setCloseInquiry, userName }) {
  const [value, setValue] = useState();
  console.log("oneUser", oneUser);
  const [showDeletedSuccess, setShowDeletedSuccess] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadCount, setLoadCount] = useState(null);
  const { notificationData, customersId } = useNotification();
  const [modalClose, setModalClose] = useState(false);

  console.log(notificationData);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const { TextArea } = Input;
  const ShowDeleteModal = () => {
    setOpenDeleteModal(true);
    // console.log('first')
  };
  const handleOk = () => {
    // onDelete('123')
    setOpenDeleteModal(false);

    setModalClose(false);
    setShowDeletedSuccess(true);
    setTimeout(() => {
      setShowDeletedSuccess(false);
    }, 1000);
  };
  const handleCancel = () => {
    setOpenDeleteModal(false);
    setShowDeletedSuccess(false);
  };

  console.log("OneUser " + oneUser);

  const handleInquiryCloseModal = () => {
    setOpenDeleteModal(false);
    setModalClose(true)
  }

  return (
    <div className="chatRightHeader">
      <div className="HeaderRight">
        {oneUser.customer_profile_pic !== null ?
          <Avatar
            size="large"
            src={
              oneUser.customer_profile_pic

            }
          /> :
          <div className="p-2"><UserOutlined /></div>
        }
        <div className="HeaderRightDetails">
          <div className="HeaderName" style={{ display: "inline" }}>
            {oneUser.customer_name ? oneUser.customer_name : userName}
            <p style={{ display: "inline", marginLeft: "10px" }}>
              <span>{conversion(oneUser.id || customersId, "inquiry", oneUser.service_type)}</span>
            </p>
          </div>

          <p className="HeaderMsg">
            {oneUser.email
              ? oneUser.email
              : ""}
          </p>
        </div>
      </div>
      <EllipsisDropdown
        menu={
          <Menu>
            {inboxType !== "Closed" && <>
              <Menu.Item key={`${customerId}_close`}>
                <span
                  onClick={ShowDeleteModal}
                  className="d-flex align-items-center"
                >
                  <Icon className="mr-2" component={Cross} />
                  Close Inquiry
                </span>
              </Menu.Item>
              {
                !closeEnquiry &&
                <Menu.Item key={`${customerId}_change`}>
                  <span
                    // onClick={ShowDeleteModal}
                    className="d-flex align-items-center"
                  >
                    <Icon className="mr-2" component={PersonSearchIcon} />
                    Change Assignee
                  </span>
                </Menu.Item>
              }
            </>
            }
            {
              !closeEnquiry &&
              <Menu.Item key={`${customerId}_details`}>
                <span
                  // onClick={ShowDeleteModal}
                  className="d-flex align-items-center"
                >
                  <Icon className="mr-2" component={PreviewRequest} />
                  Service Details
                </span>
              </Menu.Item>
            }

          </Menu>
        }
      />
      {/* {openDeleteModal ?
        <InquirychatCloseModal setOpenDeleteModal={setOpenDeleteModal} openDeleteModal={openDeleteModal}/> 
        
        : null} */}



      <Modal
        title={<span style={{ color: "#0099A8", fontWeight: "bold" }}>Close Inquiry</span>}
        height={700}
        visible={openDeleteModal}
        onOk={() => handleInquiryCloseModal()}
        onCancel={() => setOpenDeleteModal(false)}

        style={{ right: "0px", width: "500px", height: "100vh", top: "0px", float: "right" }}
      >
        <p>Add Select what went Wrong below</p>
        <div style={{ height: "inherit" }}>
          {/* <Icon className="" /> */}
        </div>
        <div style={{ justifyContent: "center" }}>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>Duplicate Inquiry</Radio>
              <Radio value={2}>No Real Need/ Fake Inquiry</Radio>
              <Radio value={3}>Out of Scope</Radio>
              <Radio value={4}>Other</Radio>
            </Space>
          </Radio.Group>
          <div></div>
          <label>Remark</label>
          <TextArea rows={4} placeholder="Type here " />
        </div>
      </Modal>





      <Modal
        visible={modalClose}
        onOk={handleOk}
        // onCancel={handleCancel}
        centered
        footer={[
          <Button
            style={{ color: "#000B23" }}
            onClick={handleCancel}
            className="font-weight-bold"
          >
            No, cancle
          </Button>,
          <Button
            style={{ backgroundColor: "#F78DA7", color: "#F5F5F5" }}
            className="font-weight-bold"
            onClick={() => {
              handleOk();
              setCloseInquiry(true);
            }}

          // onClick={setCloseEnquiry(true)}
          >
            Yes, Confirm
          </Button>,
        ]}
      >
        <div
          style={{ color: "#000B23" }}
          className="font-weight-bolder font-size-md"
        >
          Sure you want to delete?
        </div>
        <p
          style={{ color: "#72849A" }}
          className="font-weight-normal font-size-sm"
        >
          chat with john smith will deleted.
        </p>
      </Modal>
      {/* <Modal
        centered
        visible={showDeletedSuccess}
        footer={[null]}
        onCancel={() => {
          setShowDeletedSuccess(false);
        }}
      >
        {/* <SuccessSubmit icon={Successfully} title="Chat deleted successfully!" desc='chat with staff name john smith deleted.' /> */}
      {/* </Modal> * /} */}
    </div >
  );
}

export default Header;
