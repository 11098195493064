import React, { useEffect, useState } from "react";
import Bottom from "./Bottom";
import Header from "./Header";
import { socket } from "services/Socket";

function ChatRight({ customerId, type, userName }) {
  console.log("UserNAme " + userName);
  console.log("ChatRigt Noti:" + customerId);
  const [loadCount, setLoadCount] = React.useState(0);
  const [closeInquiry, setCloseInquiry] = useState(false);
  console.log("type" + type)

  useEffect(() => {
    console.log("Current" + JSON.stringify(customerId));
    if (customerId) {
      console.log("i m in chat right")
      console.log("current chatID" + customerId)
      socket.emit("join", {
        chat_id: customerId.chat_room_id,
        inquiry_id: customerId.id,
        user_id: customerId.user_id,
      });
    }
  }, [customerId]);

  if (customerId) {
    return (
      <>
        <Header key={customerId.id} customerId={customerId} userName={userName} inboxType={type} oneUser={customerId} setLoadCount={setLoadCount} closeInquiry={closeInquiry} setCloseInquiry={setCloseInquiry} />
        <Bottom customerId={customerId} loadCount={loadCount} inboxType={type} />
      </>
    );
  } else {
    return <></>;
  }
}

export default ChatRight;
