import React from "react";

import { Row, Col, Card } from "antd";

import Profile from "./Profile";
import FinanceTable from "./FinanceTable";
import AddressTable from "./AddressTable";


const TabContentAddress = ({userData}) => {

  const addressess = userData===null ? [] : userData.addresses

  return (
    <Row gutter={20}>
      <Col span={6}>
        <Card style={{ height: 600 }} className="ScrollY">
          <Profile userData={userData===null ? "" : userData} url={userData===null ? "" : userData.profile_pic} />
        </Card>
      </Col>
      <Col span={18}>
        <Card >
          <AddressTable addressess={addressess} />
        </Card>
      </Col>
    </Row>
  );
};

export default TabContentAddress;
