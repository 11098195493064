import { UPDATE_CHAT } from "redux/constants/Chat"


const Chat = (state = {detailChat:[]}, action) => {
    switch(action.type){
        case UPDATE_CHAT:
            console.log(action.chatDetails);
          return {
            ...state,
            detailChat: action.chatDetails
          }
    
        default: 
          return state
      }
}

export default Chat