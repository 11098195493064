import {
  BASIC_DETAILS,
  ADDRESSES,
  SET_ID,
  SET_EDITING_MODE,
  SET_PROFILE_PIC,
  CLEAR,
} from "redux/constants/CustomerForm";

const initialState = {
  basicDetails: {
    fullname: null,
    email: null,
    phone: null,
    dob: null,
    gender: null,
    age_group: null,
  },
  profilePic: null,
  addresses: [],
  isEditing: false,
  id: null,
  
};

const customerForm = (state = initialState, action) => {
  switch (action.type) {
    case BASIC_DETAILS:
      return {
        ...state,
        basicDetails: action.basicDetails,
      };

    case ADDRESSES:
      return {
        ...state,
        addresses: action.addresses,
      };

    case SET_ID:
      return{
        ...state,
        id: action.id,
      }

    case SET_EDITING_MODE:
      return {
        ...state,
        isEditing: action.editMode,
      };

    case SET_PROFILE_PIC:
      return{
        ...state,
        profilePic: action.profilePic
      }

    case CLEAR:
      return {
        ...state,
        basicDetails: {
          fullname: null,
          email: null,
          phone: null,
          dob: null,
          gender: null,
          age_group: null,
        },
        addresses: [],
        isEditing: false,
        id: null
      };

    default:
      return state;
  }
};

export default customerForm;
