import React from 'react'
import { Button } from 'antd'

const UploadDocuments = (props) => {

  const {onNext} =props
  const submitHandler = () => {
    onNext();
  }
  return (
    <div className="d-flex justify-content-end actions">
          <Button>Back</Button>
          <Button>Clear All</Button>
          
            <Button type="primary" onClick={submitHandler}>Next</Button>
          
        </div>
  )
}

export default UploadDocuments