import React, { useState, useEffect } from "react";
import { Table, Button, Tag, Modal } from "antd";
import Actions from "./Actions";
import { useSelector } from "react-redux";
import { useAxiosRequest } from "hooks/axios-hook";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { WhatsappIcon } from "assets/svg/icon";
import { camelCase } from "utils/CamelCase";
import { conversion } from "components/ConversionOfId/conversion";

const columns = [
  {
    title: "Inquiry Id ",
    dataIndex: "id",
    defaultSortOrder: "descend", // Set default sorting order to descend (decreasing order)
    sorter: (a, b) => a.id - b.id,
    render: (inquiryId) => {
      return conversion(inquiryId, "inquiry", null);
    },
  },
  {
    title: "Inquiry Type",
    dataIndex: "inquiry_type",
  },
  {
    title: "Inquiry Date",
    dataIndex: "Inquiry_Date",
  },
  {
    title: "Assigned To",
    dataIndex: "Assigned_To",
  },
  {
    title: "Inquiry Channel",
    dataIndex: "Inquiry_Channel",
  },
  {
    title: "Status",
    dataIndex: "status",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.satus - b.status,

    render: (status) => {
      var color = status;
      switch (status) {
        case "open":
          color = "green";
          break;
        case "closed":
          color = "red";
          break;
        default:
          color = "red";
          break;
      }

      return (
        <span>
          <Tag color={color} key={status}>
            {camelCase(status)}
          </Tag>
        </span>
      );
    },
  },

  // {
  //   title: "Action",
  //   dataIndex: "",
  //   key: "x",
  //   render: (record) => <Actions id={record.id} type="InquiryList" />,
  // },
];

const data = [
  {
    key: 1,
    Inquiry_Id: "HCI123-ORD",
    Inquiry_Type: "Service Progress",
    Inquiry_Date: "19/08/2022",
    Assigned_To: "Wade Warren",
    Inquiry_Channel: "Email",
    status: "close",
  },
];
for (let i = 2; i <= 7; i++) {
  data.push({
    key: i,
    Inquiry_Id: `HC1123${i}-ORD`,
    Inquiry_Type: "General Enquiry",
    Inquiry_Date: "19/08/2022",
    Assigned_To: "Wade Warren",
    Inquiry_Channel: "Whatsapp",
    status: "open",
  });
}

const InquiriesAndServices = ({status,service_type}) => {
  const [inquiry, setinquiry] = useState([]);

  const { primaryId, secondaryId } = useSelector(
    (state) => state.accountDetails
  );

  const { isLoading, error, sendRequest, clearError } = useAxiosRequest();

  const history = useHistory();

  useEffect(() => {
    if (!primaryId) {
      Modal.error({
        title: "ERROR",
        content: "Invalid Primary Id",
        onOk() {
          history("account-list");
        },
      });
      return;
    }
    const fetchPayments = async () => {
      const res = await sendRequest(`/inquiry/get-all-inquires/${primaryId}`);
console.log(res)
      if (secondaryId) {
        const res2 = await sendRequest(
          `/inquiry/get-all-inquiry-by-id/${secondaryId}`
        );

        res = { ...res, ...res2 };
        console.log(res)
      }

      setinquiry(res);
    };
    fetchPayments();
  }, [primaryId, secondaryId, sendRequest]);

  if (error) {
    clearError();
    Modal.error({
      title: "ERROR",
      content: error,
      onOk() {
        history.push("account-list");
      },
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectChangeHandler = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: selectChangeHandler,
  };
  console.log(inquiry);
  const tablePayments = inquiry.map((pay, i) => {
    return {
      key: i,
      id: pay.id,
      inquiry_type: pay.inquiry_type,
      Inquiry_Date: moment(pay.Date_Of_Inquiry).format("DD/MM/YYYY"),
      Assigned_To: pay.Assigned_To,
      Inquiry_Channel: pay.inquiry_Channel,
      service_type: pay.service_type,
      status: pay.Status,
    };
  });

  const filteredTablePayments = tablePayments.filter((payment) => {
    if (status === 'open' && payment.status === 'open') {
      return true;
    } else if (status === 'closed' && payment.status === 'closed') {
      return true;
    } else if (!status) {
      return true; // Show all if status is not specified
    }
    return false;
  });

  return (
    <div>
      <Table
        pagination={{
          // pageSize: 5,
          total: filteredTablePayments.length,
          // showTotal: (total, range) =>
          //   `${range[0]}-${range[1]} of ${total} items`,
        }}
        
        columns={columns}
        dataSource={filteredTablePayments}
        className="w-100"
      />
    </div>
  );
};

export default InquiriesAndServices;
