import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Table, Modal, Avatar } from "antd";
import axios from "axios";
import _ from "lodash";

import Actions from "./Actions";
import {
  setEditingMode,
  updateBasicDetails,
  addAddress,
  setId,
  setPrimaryAccount,
  setSecondaryAccount,
  clearAccountDetails,
  setProfilePic,
} from "redux/actions";
import { UserOutlined } from "@ant-design/icons";

// const data = [
//   {
//     key: 1,
//     customerId: 'HC-12345',
//     image: '',
//     customerName: 'John Smith',
//     phoneNumber: '+65 123456789',
//     emailId: 'johnsmith@gmail.com',
//     totalRevenue: 'S$100',
//     outstandingAmount: 'S$100',
//     action: ''

//   }
// ];
// for(let i = 2 ; i <= 10 ; i++){
//   data.push({
//     key: i,
//     customerId: 'HC-12345',
//     image: '',
//     customerName: 'John Smith',
//     phoneNumber: '+65 123456789',
//     emailId: 'johnsmith@gmail.com',
//     totalRevenue: 'S$100',
//     outstandingAmount: 'S$100',
//     action: ''
//   })
// }

const AccountListTable = (props) => {
  const {
    searchText,
    loadedCustomers,
    setLoadedCustomers,
    isLoading,
    setIsLoading,
    setPage,
    totalSize,
    setPageNumber,
    pageSize,
    setPageSize,
    pageNumber
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [customersBackend, setCustomersBackend] = useState([]);

  const history = useHistory();

  const dispatch = useDispatch();

  const editHandler = async (id) => {
    console.log("id", id);
    try {
      id = id.slice(3);

      const res = await axios.get(`/customers/get-customer/${id}`);

      if (!res.data.success) {
        throw new Error(res.data.data);
      }

      const editCustomer = res.data.data;
      console.log(editCustomer);
      const { fullname, email, phone, dob, gender, profile_pic } = editCustomer;

      const basicDetails = {
        fullname,
        email,
        phone,
        dob,
        gender,
        profile_pic,
      };
      let addresses = [];

      if (!+editCustomer.addresses.length == 0) {
        addresses = editCustomer.addresses.map((addr) => {
          return {
            block_no: addr.block_no,
            unit_no: addr.unit_no,
            level_no: addr.level_no,
            pin_code: addr.pin_code,
            street_name: addr.street_name,
            country_id: addr.country_id,
          };
        });
      }

      dispatch(setEditingMode(true));
      dispatch(setProfilePic(basicDetails.profile_pic));
      dispatch(updateBasicDetails(basicDetails));
      dispatch(addAddress(addresses));
      dispatch(setId(id));
    } catch (err) {
      console.log(err);
    } finally {
      history.push("/app/account-management/edit-new-account?id=" + id);
    }
  };

  const deleteHandler = async (id) => {
    try {
      setIsLoading(true);
      id = id.slice(3);
      const res = await axios.delete(`/customers/delete-customer/${id}`);

      if (!res.data.success) {
        throw new Error("Can't delete the given customer");
      }

      Modal.success({
        content: `Successfull Deleted Customer. Customer id: ${id}`,
      });

      setLoadedCustomers((prev) => prev.filter((cust) => cust.id != id));
    } catch (err) {
      Modal.error({
        title: "Something went wrong",
        content: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const viewDetailsHandler = async (id) => {
    try {
      dispatch(clearAccountDetails());
      id = id.slice(3);

      const res = await axios.get(`/customers/get-customer/${id}`);

      if (!res.data.success) {
        throw new Error(res.data.data);
      }

      const viewCustomer = res.data.data;

      const { fullname, email, phone, dob } = viewCustomer;

      let address = null;
      if (+viewCustomer.addresses.length != 0) {
        const { level_no, block_no, street_name, country_name } =
          viewCustomer.addresses[0];
        address = `${level_no}, ${block_no}, ${street_name}, ${country_name}`;
      }

      const primaryAccount = {
        fullname,
        email,
        phone,
        dob,
        address,
      };

      // console.log(primaryAccount);

      let secondaryAccount;

      if (viewCustomer.secondary_acc_id) {
        const res = await axios.get(
          `/customers/get-customer/${viewCustomer.secondary_acc_id}`
        );

        if (!res.data.success) {
          throw new Error(res.data.data);
        }

        const secondary_customer = res.data.data;

        const { fullname, phone, email } = secondary_customer;

        let address = null;
        if (+secondary_customer.addresses.length != 0) {
          const { level_no, block_no, street_name, country_name } =
            secondary_customer.addresses[0];
          address = `${level_no}, ${block_no}, ${street_name}, ${country_name}`;
        }

        secondaryAccount = {
          fullname,
          phone,
          email,
          address,
        };

        // console.log(secondaryAccount);
        dispatch(
          setSecondaryAccount(secondaryAccount, viewCustomer.secondary_acc_id)
        );
      }
      dispatch(setPrimaryAccount(primaryAccount, id));
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const columns = [
    {
      title: "Customer Id",
      dataIndex: "customerId",
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return (
      //     _.isMatch(_.toLower(record.customerName), _.toLower(value)) ||
      //     _.isMatch(_.toLower(record.customerId), _.toLower(value)) ||
      //     _.isMatch(_.toLower(record.totalRevenue), _.toLower(value)) ||
      //     _.isMatch(record.phoneNumber, value) ||
      //     _.isMatch(_.toLower(record.emailId), _.toLower(value)) ||
      //     _.isMatch(_.toLower(record.outstanding_amounts), _.toLower(value))
      //   );
      // },
    },
    {
      title: "Profile",
      dataIndex: "profile_pic",
      render: (profile_pic) => {
        return (
          <Avatar
            style={{
              backgroundColor: "#fde3cf",
              color: "#f56a00",
            }}
            icon={<UserOutlined />}
            src={profile_pic}
            shape="circle"
          />
        );
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      sorter: (a, b) => {
        if (a.customerName === null && b.customerName === null) {
          return 0;
        }
        else if (a.customerName === null) {
          return 1;
        }
        else if (b.customerName === null) {
          return -1;
        }
        else {
          return a.customerName.localeCompare(b.customerName)
        }
      },
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Email Id",
      dataIndex: "emailId",
    },
    {
      title: "Total Revenue",
      dataIndex: "totalRevenue",
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstanding_amounts",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        // console.log(record);

        const id = record.customerId;

        return (
          <Actions
            onEdit={editHandler}
            onDelete={deleteHandler}
            onViewDetails={viewDetailsHandler}
            id={id}
          />
        );
      },
    },
  ];

  const data = loadedCustomers.map((cust, index) => {
    console.log(cust)
    return {
      key: index,
      customerId: `HC-${cust.id}`,
      profile_pic: cust.profile_pic,
      customerName: cust.fullname,
      phoneNumber: cust.phone,
      emailId: cust.email,
      totalRevenue: `${cust.total_revenue.toFixed(2)}`,
      outstanding_amounts: `${cust.outstanding_amounts}`,
    };
  });

  const selectChangeHandler = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: selectChangeHandler,
  };

  console.log(rowSelection);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        // pagination={{
        //   // total: 10,
        //   // pageSize: 5,
        //   onChange: (page) => {
        //     setPage(page);
        //   }
        // }}

        pagination={{
          currentPage: pageNumber,
          pageSize: pageSize,
          total: totalSize,
          onChange: (page, pageSize) => {
            setPageNumber(page);

          },
        }} // Disable pagination for virtualization
      // scroll={{ y: 300 }}
      // rowSelection={rowSelection}
      />
    </div>
  );
};

export default AccountListTable;
