export const BasicDetailsIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9824 15.0869C12.2598 15.0781 10.9238 13.6367 10.9238 11.7295C10.9062 9.93652 12.2686 8.44238 13.9824 8.44238C15.6963 8.44238 17.041 9.93652 17.041 11.7295C17.041 13.6367 15.7051 15.1045 13.9824 15.0869ZM13.9824 21.2393C12.0137 21.2393 9.92188 20.4219 8.59473 18.998C9.60547 17.4863 11.6094 16.6074 13.9824 16.6074C16.3291 16.6074 18.3506 17.4688 19.3701 18.998C18.043 20.4219 15.9512 21.2393 13.9824 21.2393Z" fill="#455560"/>
</svg>

);

export const BasicDetailsActiveIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9824 15.0869C12.2598 15.0781 10.9238 13.6367 10.9238 11.7295C10.9062 9.93652 12.2686 8.44238 13.9824 8.44238C15.6963 8.44238 17.041 9.93652 17.041 11.7295C17.041 13.6367 15.7051 15.1045 13.9824 15.0869ZM13.9824 21.2393C12.0137 21.2393 9.92188 20.4219 8.59473 18.998C9.60547 17.4863 11.6094 16.6074 13.9824 16.6074C16.3291 16.6074 18.3506 17.4688 19.3701 18.998C18.043 20.4219 15.9512 21.2393 13.9824 21.2393Z" fill="#0099A8"/>
</svg>

);

export const AddressDetaisIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1924)">
      <path
        d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1924">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AddressDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1848)">
<path d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z" fill="#0099A8"/>
</g>
<defs>
<clipPath id="clip0_467_1848">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export const OnsiteIcon = () => (
  <svg width="19" height="28" viewBox="0 0 19 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50051 0.000991111C4.24663 0.000991111 0 4.24731 0 9.50042C0 10.7284 0.593212 12.6061 1.51846 14.6572C2.44371 16.7083 3.68682 18.9521 4.93095 21.0246C7.2137 24.8264 9.1381 27.4925 9.50051 28C9.8619 27.4925 11.7863 24.8264 14.069 21.0246C15.3142 18.9521 16.5573 16.7083 17.4815 14.6572C18.4068 12.6061 19 10.7275 19 9.49943C19 4.24733 14.7534 0 9.50051 0V0.000991111ZM9.50051 2.03079C13.6874 2.03079 17.0928 5.44389 17.0928 9.64252C17.0928 13.8401 13.6874 17.2542 9.50051 17.2542C5.31259 17.2542 1.9082 13.8401 1.9082 9.64252C1.9082 5.4449 5.31259 2.03079 9.50051 2.03079ZM9.50051 3.04568C8.63611 3.04462 7.77999 3.21456 6.98119 3.5457C6.18239 3.87684 5.45659 4.36272 4.84537 4.9755C4.23415 5.58829 3.74951 6.31595 3.41921 7.1168C3.08891 7.91764 2.91944 8.77591 2.92051 9.64252C2.92051 13.2921 5.86025 16.2394 9.50051 16.2394C13.1398 16.2394 16.0805 13.2921 16.0805 9.64252C16.0805 5.99295 13.1398 3.04568 9.50051 3.04568ZM9.50051 4.56803L12.0667 6.94286V6.83123C12.0652 6.76448 12.077 6.69814 12.1013 6.63598C12.1256 6.57382 12.162 6.51706 12.2083 6.4691C12.2546 6.42115 12.31 6.38296 12.3712 6.35661C12.4324 6.33027 12.4982 6.31639 12.5648 6.31573C12.8502 6.31573 13.0831 6.54503 13.079 6.83123V7.75284C13.0793 7.79174 13.0752 7.83056 13.0669 7.86855L14.0559 8.78186H12.7976V13.7001H10.3681V10.5387H8.63195V13.7001H6.20241V8.78186H4.94512L9.50051 4.56803Z" fill="#455560"/>
</svg>

)

export const InhouseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0094 0L0 10.1101L2.35073 11.4314L9.94358 3.31331L17.6493 11.4314L20 10.1101L10.0094 0ZM3.19699 12.8729L10.0188 5.61061L16.8218 12.8729V20H11.8524V14.995H8.12412V20H3.19699V12.8729Z" fill="#455560"/>
</svg>

)

export const OnsiteActiveIcon = () => (
  <svg width="19" height="28" viewBox="0 0 19 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50051 0.000991111C4.24663 0.000991111 0 4.24731 0 9.50042C0 10.7284 0.593212 12.6061 1.51846 14.6572C2.44371 16.7083 3.68682 18.9521 4.93095 21.0246C7.2137 24.8264 9.1381 27.4925 9.50051 28C9.8619 27.4925 11.7863 24.8264 14.069 21.0246C15.3142 18.9521 16.5573 16.7083 17.4815 14.6572C18.4068 12.6061 19 10.7275 19 9.49943C19 4.24733 14.7534 0 9.50051 0V0.000991111ZM9.50051 2.03079C13.6874 2.03079 17.0928 5.44389 17.0928 9.64252C17.0928 13.8401 13.6874 17.2542 9.50051 17.2542C5.31259 17.2542 1.9082 13.8401 1.9082 9.64252C1.9082 5.4449 5.31259 2.03079 9.50051 2.03079ZM9.50051 3.04568C8.63611 3.04462 7.77999 3.21456 6.98119 3.5457C6.18239 3.87684 5.45659 4.36272 4.84537 4.9755C4.23415 5.58829 3.74951 6.31595 3.41921 7.1168C3.08891 7.91764 2.91944 8.77591 2.92051 9.64252C2.92051 13.2921 5.86025 16.2394 9.50051 16.2394C13.1398 16.2394 16.0805 13.2921 16.0805 9.64252C16.0805 5.99295 13.1398 3.04568 9.50051 3.04568ZM9.50051 4.56803L12.0667 6.94286V6.83123C12.0652 6.76448 12.077 6.69814 12.1013 6.63598C12.1256 6.57382 12.162 6.51706 12.2083 6.4691C12.2546 6.42115 12.31 6.38296 12.3712 6.35661C12.4324 6.33027 12.4982 6.31639 12.5648 6.31573C12.8502 6.31573 13.0831 6.54503 13.079 6.83123V7.75284C13.0793 7.79174 13.0752 7.83056 13.0669 7.86855L14.0559 8.78186H12.7976V13.7001H10.3681V10.5387H8.63195V13.7001H6.20241V8.78186H4.94512L9.50051 4.56803Z" fill="#0099A8"/>
</svg>

)

export const InhouseActiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0094 0L0 10.1101L2.35073 11.4314L9.94358 3.31331L17.6493 11.4314L20 10.1101L10.0094 0ZM3.19699 12.8729L10.0188 5.61061L16.8218 12.8729V20H11.8524V14.995H8.12412V20H3.19699V12.8729Z" fill="#0099A8"/>
</svg>

)