import React, { useState, useEffect } from "react";
import { Table, Button, Tag, Modal, Select } from "antd";
import { Form, Input, Menu, message } from "antd";
import Actions from "./Actions";
import { WhatsappIcon } from "assets/svg/icon";
import { useSelector } from "react-redux";
import { useAxiosRequest } from "hooks/axios-hook";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import axios from "axios";
import { BASE_URL } from "services/API";

const data = [
  {
    key: 1,
    Invoice_Id: "HCI123-INV",
    Payment_Id: "HCI123-PAY",
    Item: "XYZ Hand Bag",
    Payment_Date: "19/08/2022",
    Amount: "S$123.00",
    Payment_Type: "PayPal",
    status: "paid",
  },
];
for (let i = 2; i <= 7; i++) {
  data.push({
    key: i,
    Invoice_Id: `HCI123${i}-INV`,
    Payment_Id: `HCI123${i}-PAY`,
    Item: "XYZ Hand Bag",
    Payment_Date: "19/08/2022",
    Amount: "S$123.00",
    Payment_Type: "CASH",
    status: "INVALID",
  });
}

const AddressTable = ({ addressess }) => {
  const [coData, setCoData] = useState();
  const { primaryId, secondaryId } = useSelector(
    (state) => state.accountDetails
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectChangeHandler = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: selectChangeHandler,
  };

  const columns = [
    {
      title: "SR No",
      dataIndex: "id",
    },
    {
      title: "Block No.",
      dataIndex: "block_no",
    },
    {
      title: "Street Name",
      dataIndex: "street_name",
    },
    {
      title: "Level No",
      dataIndex: "level_no",
    },
    {
      title: "Unit No.",
      dataIndex: "unit_no",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Postal Code",
      dataIndex: "pin_code",
    },

    {
      title: "Action",
      // dataIndex: "",
      // key: "x",
      render: (record) =>{
        return (
          <EllipsisDropdown
      menu={
        <Menu>
          <Menu.Item>
            <div onClick={()=>editModal(record)} className="d-flex" style={{ gap: "10px" }}>
              <div>
                <EditOutlined />
              </div>
              {"   "} <div>Edit</div>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="d-flex" onClick={()=>deleteApi(record.itemId)} style={{ gap: "10px" }}>
              <div>
                <DeleteOutlined />
              </div>
              {"   "} <div>Delete</div>
            </div>
          </Menu.Item>
        </Menu>
      }
    />
        )
      },
    },
  ];

  console.log("addressess", addressess);

  const deleteApi = async (id) => {

      const res = await axios.delete(
        `/customers/delete-customer-address/${id}`,
      );
      message.success("Address Deleted Succesfully");
		let storing = [...addressTables];
		storing = storing.filter((item)=>item.itemId!==id)
		setAddressTables(storing)
    
  };


  const { isLoading, error, sendRequest, clearError } = useAxiosRequest();
  const history = useHistory();
  const countries = useSelector((state) => state.countries.countries);
  const [isEditModalOpen ,setIsEditModalOpen] = useState(false);
  const [addressTables,setAddressTables] = useState(addressess.map((address, i) => {
    return {
      key: i,
      id: i + 1,
      itemId:address.id,
      block_no: address.block_no,
      street_name: address.street_name,
      level_no: address.level_no,
      unit_no: address.unit_no,
      country: address.country,
      country_id:address.country_id,
      pin_code: address.pin_code,
    };
  }))

  const [form] = Form.useForm();
  const [pinCode ,setPincode]=useState(true);
  const updateData = async (value)=>{
    if(!pinCode){
      message.warn("Wrong Postal Code")
    }
    try{
      const res = await axios.patch(`/customers/update-customer-address/${addressId}`,{...value});
      if(res.status===200){
        message.success("Address Updated");
        for(let i = 0 ;i<addressTables.length;i++){
          if(addressTables[i]["itemId"]===addressId){
            let storing = [...addressTables];
            storing[i]={...storing[i],...value}
            setAddressTables(storing);
            setIsEditModalOpen(false);
            return;
          }
          
        }
      }
    }catch(err){
      console.log(err);
    }
	}
 const [addressId,setAddressId]=useState("");
  const editModal = async(record) =>{
    setIsEditModalOpen(true)
    form.setFieldsValue({...record})
    setAddressId(record.itemId);
    
  }

  let getData;
  const functionForStreet = async (val, index) => {
    clearTimeout(getData)
    getData = setTimeout(() => {
      pincodeApi(val, index);
    }, 2000)
  }

  const pincodeApi = async(val)=>{
    await fetch(`${BASE_URL}/api/get-pincode`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            pin_code: val
          })
        })
        .then(response => response.json())
        .then(data => {
          // const result = data.results[0];
          if(!data.results.length){
            message.warn("Wrong Postal Code")
            setPincode(false);
            return;
          }
          setPincode(true);
  
          console.log("length",data.results.length);
        })
        .catch(error => {
          console.error('Error:', error);
        });
        
  }

  return (
    <div>
      <Table
        pagination={{
          // pageSize: 5,
          total: addressTables?.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        
        row={"id"}
        columns={columns}
        dataSource={addressTables}
        className="w-100"
      />
      <Modal
				width={1000}
				footer={null}
				visible={isEditModalOpen}
				// onOk={changeStudHandleOk}
				onCancel={() => setIsEditModalOpen(false)}
			  >
				 <Form  form={form}  onFinish={updateData}>
				<div className="d-flex my-3 flex-column w-100">
				  <h3 className="mb-4 d-flex align-items-center">
					{/* {" "} */}
					{/* <EditOutlined/>{" "} */}
					<span className="ml-2">Edit Traveller</span>
				  </h3>
				
				
				  <div className='d-flex' style={{justifyContent:"space-between"}}>
				  <div style={{width:"45%"}}>
				  <label>Block Number</label>
				 <Form.Item name='block_no' 
				// rules={[
				//   {
				// 	required: true,
				// 	message: ``,
				//   },
				// ]} 
				 >
				   <Input onKeyPress={(e)=>{
             const charCode = e.which || e.keyCode;
             if (charCode < 48 || charCode > 57) {
               e.preventDefault();
             }
            
           }} placeholder='Block Number' />
				  </Form.Item>
				  </div>
				  <div style={{width:"45%"}}>
				  <label>Street Name</label>
				  <Form.Item  
          // rules={[
					// {
					//   required: true,
					//   message: ``,
					// },
				  // ]}
           name='street_name' >
				   <Input placeholder='Street Name' />
				  </Form.Item>
				  </div>
				  </div>
		
				  <div className='d-flex' style={{justifyContent:"space-between"}}>
				  <div style={{width:"45%"}}>
				  <label>Level Number</label>
				 <Form.Item name='level_no' 
				// rules={[
				//   {
				// 	required: true,
				// 	message: ``,
				//   },
				// ]}
				 >
				   <Input onKeyPress={(e)=>{
             const charCode = e.which || e.keyCode;
             if (charCode < 48 || charCode > 57) {
               e.preventDefault();
             }
          
           }} placeholder='Level Number' />
				  </Form.Item>
				  </div>
				  <div style={{width:"45%"}}>
				  <label>Unit Number</label>
				  <Form.Item 
          // rules={[
					// {
					//   required: true,
					//   message: ``,
					// },
				  // ]} 
          name='unit_no' >
				   <Input  onKeyPress={(e)=>{
             const charCode = e.which || e.keyCode;
             if (charCode < 48 || charCode > 57) {
               e.preventDefault();
             }
             
           }} style={{width:"100%"}}  placeholder='Unit Number' />
				  </Form.Item>
				  </div>
				  </div>
		
		
				  <div className='d-flex' style={{justifyContent:"space-between"}}>
				  <div style={{width:"45%"}}>
				  <label>Postal Code</label>
				 <Form.Item name='pin_code' 
				//  rules={[
        //   { required: true, },
        //   { pattern: /^\d{6}$/, message: "Postal code must be exactly 6 digits." },
        // ]}
				 >
				<Input onKeyPress={(e)=>{
           const charCode = e.which || e.keyCode;
           if (charCode < 48 || charCode > 57) {
             e.preventDefault();
           }
           if (e.target.value.length >= 6) {
             e.preventDefault();
           }
        }} onChange={(e)=>functionForStreet(e.target.value)}/>
				   {/* <Input placeholder='Gender' /> */}
				  </Form.Item>
				  </div>
				  <div style={{width:"45%"}}>
				  <label>Country</label>
				  <Form.Item 
          //  rules={[
					// {
					//   required: true,
					//   message: ``,
					// },
				  // ]} 
          name='country_id' >
				   <Select>
                  {countries &&
                    countries.map((country) => {
                      return (
                        <Select.Option value={country.id}>
                          {country.name}
                        </Select.Option>
                      );
                    })}
                </Select>
				  </Form.Item>
				  </div>
				  </div>
		
			  
				</div>
				<div
				  style={{ gap: "10px" }}
				  className="mt-5 d-flex justify-content-end"
				>
				  <Button
					className="px-4 font-weight-semibold"
					onClick={() => setIsEditModalOpen(false)}
				  >
					Cancel
				  </Button>
				  <Button
				   htmlType="submit"
					className="px-4 font-weight-semibold text-white bg-info"
				   
				  >
					Save
				  </Button>
				</div>
				</Form>
			  </Modal>
    </div>
  );
};

export default AddressTable;
