import React, { useEffect, useState } from "react";
import { Table, Button, Tag, Modal, message } from "antd";
import Actions from "./Actions";
import { useAxiosRequest } from "hooks/axios-hook";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { camelCase } from "utils/CamelCase";

const data = [
  {
    key: 1,
    itemId: "HC11234-ORD-(1 - 1)",
    item: "XYZ Hand Bag",
    qty: "1 set",
    amount: "S$100.00",
    serviceType: "In House",
    status: "Delivered",
  },
];
for (let i = 2; i <= 7; i++) {
  data.push({
    key: i,
    itemId: `HC11234-ORD-(1 - ${i})`,
    item: "XYZ Hand Bag",
    qty: "1 set",
    amount: "S$100.00",
    serviceType: "In House",
    status: "Delivered",
  });
}

const InquiriesAndServices = (props) => {
  const [items, setItems] = useState();

  const { tab1FilterStatus, tab1FilterServiceType } = props;

  const { isLoading, error, sendRequest, clearError } = useAxiosRequest();

  const { primaryId, secondaryId } = useSelector(
    (state) => state.accountDetails
  );

  const history = useHistory();

  useEffect(() => {
    console.log(primaryId, secondaryId);

    if (!primaryId) {
      history.push("account-list");
      return;
    }
    const fetchItems = async () => {
      const data = JSON.stringify({

        "status": tab1FilterStatus,
        "service_type": tab1FilterServiceType
      })
      let res = await sendRequest(
        `/customers/get-all-customer-items-accountmanagement?filters=${data.toString()}`,
        "post",
        {
          customer_id: primaryId,

        }
      );

      if (secondaryId) {
        const res2 = await sendRequest(
          `${process.env.REACT_BACKEND_URL}/customers/get-all-customer-items`,
          "post",
          { customer_id: secondaryId }
        );

        res = [...res, ...res2];
      }

      console.log(res);

      res = res.map((item) => {
        if (item.service_type === "In House") {
          item.id_number = `HCI${item.id}`;
        } else if (item.service_type === "Onsite") {
          item.id_number = `OSI${item.id}`;
        }
        return item;
      });

      setItems(res);
    };
    fetchItems();
  }, [sendRequest, primaryId, secondaryId, tab1FilterStatus, tab1FilterServiceType]);

  if (error) {
    clearError();
    Modal.error({
      title: "ERROR",
      content: error,
      onOk() {
        history.push("account-list");
      },
    });
  }

  const columns = [
    {
      title: "Item Id",
      dataIndex: "id_number",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Item",
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.item - b.item,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "final_price",
    },
    {
      title: "Service Type",
      dataIndex: "service_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.satus - b.status,

      render: (status) => {
        let color = "green";
        if (status === "pending collection") {
          color = "orange";
        } else if (status === "craftsman assigned") {
          color = "blue";
        } else if (status === "QC Passed") {
          color = "green";
        } else if (status === "In Progress") {
          color = "blue";
        }
        return (
          <span>
            <Tag color={color} key={status}>
              {camelCase(status)}
            </Tag>
          </span>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record) => <Actions id={record.id} customer_invoice_id={record.customer_invoice_id} customer_quotation_id={record.customer_quotation_id} type={"itemAndServices"} />,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectChangeHandler = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: selectChangeHandler,
  };

  const tableItems = items?.map((item, index) => {
    return { key: index, ...item };
  });

  return (
    <Table
      pagination={{
        // pageSize: 5,
        total: tableItems?.length,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,

      }}

      columns={columns}
      dataSource={tableItems}
      loading={isLoading}
      className="w-100"
    />
  );
};

export default InquiriesAndServices;
