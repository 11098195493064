import { SET_PRIMARY_ACCOUNT , SET_SECONDARY_ACCOUNT , CLEAR } from "redux/constants/AccountDetails";


const initialState = {
  primaryAccount: {
    fullname: null,
    id: null,
    dob: null,
    phone: null,
    email: null,
    address: null,
    profile_pic: null,
  },
  secondaryAccount: {
    fullname: null,
    id: null,
    phone: null,
    address: null,
    email: null,
  },
  haveSecondaryAccount: false,
  primaryId: null,
  secondaryId: null,
};

const AccountDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIMARY_ACCOUNT:
      return {
        ...state,
        primaryAccount: action.primaryAccount,
        primaryId: action.primaryId,
      };

    case SET_SECONDARY_ACCOUNT:
      return {
        ...state,
        secondaryAccount: action.secondaryAccount,
        secondaryId: action.secondaryId,
        haveSecondaryAccount: true,
      };

    case CLEAR:
      return{
        ...initialState
      }

    default:
      return state;
  }
};

export default AccountDetails