import { CLEAR, SET_PRIMARY_ACCOUNT , SET_SECONDARY_ACCOUNT } from "redux/constants/AccountDetails";

export const setPrimaryAccount = (primaryAccount , primaryId) => {
  return{
    type: SET_PRIMARY_ACCOUNT,
    primaryAccount,
    primaryId,
  }
}
export const setSecondaryAccount = (secondaryAccount , secondaryId) => {
  return{
    type: SET_SECONDARY_ACCOUNT,
    secondaryAccount,
    secondaryId
  }
}

export const clearAccountDetails = () => {
  return{
    type: CLEAR,
  }
}