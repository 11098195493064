import React, { useState } from "react";
import { useDispatch ,useSelector} from "react-redux";
import { setProfilePic, updateBasicDetails } from "redux/actions";

import { Link, useHistory } from "react-router-dom";

import { Card, Form, Button, message } from "antd";

import Form1 from "./Form1";
import "./BasicDetailsAcc.css";
import UploadProfilePic from "components/shared-components/UploadProfilePic";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";

const BasicDetailsAcc = (props) => {
  const {clearForm,userData,onNext , isInquiry, type} = props;
  const dispatch = useDispatch();
  const {isEditing,profilePic} = useSelector(state => state.customerForm);
  const history = useHistory();
  const [countries, setCountries] = useState("+65");

  const [form] = Form.useForm();

  const NextHandler = async() => {
    try{  
      const res = await axios.post("/customers/user-is-exists",{
        email:form.getFieldValue('email')?form.getFieldValue('email'):null,
        phone:form.getFieldValue('phone')
      })
      if(res){
        return true;
      }
    }catch(err){
      console.log(err.response);
      message.error(err.response.data.message)
      return false;
    }
  }

  const finishHandler = async(e) => {
    // console.log({
    //   ...e,profile_pic:profilePic
    // });
    dispatch(updateBasicDetails({
      ...e,
      country_code:countries,
      profile_pic:profilePic,
    }));
    if(type !=='edit' && profilePic===null){
      message.error('Please Upload Profile Image.!')
      return
    }
    let check = true;
    if(type!=='edit'){

       check = await NextHandler()
    }
    if(check ){
      onNext();

    }
    
  }

  const uploadProfilePickHandler = async(profilePic) => {
    // dispatch(setProfilePic(profilePic));
   
  }

  useEffect(() => {
    if (clearForm && isInquiry) {
      form.resetFields()
    }
    if (userData) {
      console.log(userData);
      dispatch(setProfilePic(userData.profile_pic));
      setCountries(userData.country_code);
      form.setFieldsValue({
        fullname:userData.fullname,
        email:userData.email,
        phone:userData.phone,
        dob:userData.dob && moment(userData.dob , "YYYY/MM/DD"),
        gender:userData.gender
      })
    }
  }, [userData, clearForm, isInquiry, form, dispatch])
  
  return (
    <div>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="vertical"
        form={form}
        // onValuesChange={onFormLayoutChange}

        onFinish={finishHandler}
      >
        <Card className="mt-3">
          <UploadProfilePic type={type} onUpload={uploadProfilePickHandler}/>
          <Form1 type={type} setCountries={setCountries} countries={countries} />
        </Card>

        <div className="d-flex justify-content-end actions">
          {!isInquiry && <Button onClick={() => history.goBack()}>Back</Button>}
          {isEditing && <Button style={{width: "135px"}}onClick={() => form.resetFields()}>Reset</Button>}
          {!isEditing && <Button onClick={() => form.resetFields()}>Clear All</Button>}
            <Button type="primary" htmlType="submit">Next</Button>
        </div>
      </Form>
    </div>
  );
};

export default BasicDetailsAcc;
