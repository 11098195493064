import { CLEAR } from "redux/constants/Customers"
import { SET_CUSTOMERS , DELETE_CUSTOMER } from "redux/constants/Customers"

export const setCustomers = (customers) => {
  return {
    type: SET_CUSTOMERS,
    customers,
  }
}

export const deleteCustomer = (customerId) => {
  return {
    type: DELETE_CUSTOMER,
    customerId,
  }
}

export const clearCustomers  =() => {
  return{
    type: CLEAR
  }
}