import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
} from "antd";
import { useHistory } from "react-router-dom";
import { country_code } from "./Country";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Form1 = (props) => {
  const { countries, type, setCountries } = props;
  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const history = useHistory();

  const { basicDetails, isEditing } = useSelector(
    (state) => state.customerForm
  );

  function disabledDate(current) {
    // Disable dates that are in the future or today
    return current && current > moment().endOf("day");
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            label={
              <div>
                Full Name<span style={{ color: "red" }}>*</span>
              </div>
            }
            name="fullname"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              {
                pattern: new RegExp(/^[A-Za-z ]+$/),
                message: "Please use alphabetic characters only.",
              },
              {
                validator: (rule, value) => {
                  if (value && value.trim() !== value) {
                    return Promise.reject("Leading spaces are not allowed.");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            initialValue={
              type === "edit" && isEditing ? basicDetails.fullname : null
            }
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Mobile Number<span style={{ color: "red" }}>*</span>
              </div>
            }
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (value.length >= 8 && value.length <= 11)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mobile number should be between 8 and 11 digits")
                  );
                },
              }),
            ]}
            initialValue={
              type === "edit" && isEditing ? basicDetails.phone : null
            }
          >
            <Input
             disabled={type === "edit" && isEditing ? true : false}
              prefix={
                <Select
                  style={{ width: "80px" }}
                  disabled={type === "edit" && isEditing ? true : false}
                  showSearch
                  onSearch={(val) => {
                    return country_code.filter((item) => {
                      return item.dial_code.includes(val);
                    });
                  }}
                  value={countries}
                  onChange={(val) => {
                    setCountries(val);
                  }}
                >
                  {country_code.map((item) => {
                    return (
                      <Select.Option value={item.dial_code}>
                        {item.dial_code}
                      </Select.Option>
                    );
                  })}
                </Select>
              }
              maxLength={11}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<div>Email</div>}
            name="email"
           rules={[
             {
              pattern: new RegExp(/^\S+@\S+\.\S+$/),
              message: "Please enter a valid email address",
             }
           ]}
           
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Date of Birth
              </div>
            }
            name="dob"
          
          >
            <DatePicker format={"DD-MM-YYYY"} disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Gender
              </div>
            }
          
            name="gender"
            initialValue={
              type === "edit" && isEditing ? basicDetails.gender : null
            }
          >
            <Radio.Group>
              <Radio value="male"> Male </Radio>
              <Radio value="female"> Female </Radio>
              <Radio value="other"> Other </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default Form1;
