import React from 'react';
import { CSVLink } from 'react-csv';
import { Button , Checkbox, Dropdown, Menu , Modal , Typography} from "antd";
import { ItemsAndServicesPageIcon , PlusIcon , ExportIcon , FilterIcon , FileExportIcon } from "assets/svg/icon";
import Icon from "@ant-design/icons"

const Csv = (props) => {
  const {header,data,filename} = props;
  console.log(header);
  return (
    <CSVLink
    headers={header}
    data={data}
    filename={filename}
    >
      <Button className="d-flex align-items-center ml-2" >
            <Icon className="mr-2" component={ExportIcon}/>Export
          </Button>
    </CSVLink>
  
  )
}

export default Csv;