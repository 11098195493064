import { Button, Dropdown, Menu, Space , Radio , Select } from "antd";
import React , {useState} from "react";

const {Option} = Select
// const menu = (
//   <Menu
//     items={[
//       {
//         key: "1",
//         label: (
//           <a
//             target="_blank"
//             rel="noopener noreferrer"
//             href="https://www.antgroup.com"
//           >
//             1st menu item
//           </a>
//         ),
//       },
//       {
//         key: "2",
//         label: (
//           <a
//             target="_blank"
//             rel="noopener noreferrer"
//             href="https://www.aliyun.com"
//           >
//             2nd menu item
//           </a>
//         ),
//       },
//       {
//         key: "3",
//         label: (
//           <a
//             target="_blank"
//             rel="noopener noreferrer"
//             href="https://www.luohanacademy.com"
//           >
//             3rd menu item
//           </a>
//         ),
//       },
//     ]}
//   />
// );



const Filter = (props) => {
  // return (
  //   <Dropdown overlay={menu} placement="bottomLeft">
  //     {props.children}
  //   </Dropdown>
  // );

  const [value, setValue] = useState(1);
  const onClick = (e) => {
    console.log(e);
  }

  const changeHandler = (value) => {
    console.log(value);
  }

  let menu = (
    <Menu onClick={onClick}>
      <Menu.Item>item 1</Menu.Item>
      <Menu.Item>item 2</Menu.Item>
    </Menu>
    // <Radio.Group onChange={changeHandler} value={value}>
    //   <Radio value={1}>A</Radio>
    //   <Radio value={2}>B</Radio>
    //   <Radio value={3}>C</Radio>
    //   <Radio value={4}>D</Radio>
    // </Radio.Group>
  );

  if(props.menu){
    menu = props.menu;
  }

  return (
    <Dropdown   overlay={menu} trigger={["click"]}>
      
      {props.children}
      
      
    </Dropdown>
    // <Select
    //   mode="multiple"
    //   style={{ width: '100%' }}
    //   placeholder="Please select"
    //   defaultValue={['Option 1', 'Option 2']}
    //   onChange={changeHandler}
    // >
    //   <Option value="Option 1">Option 1</Option>
    //   <Option value="Option 2">Option 2</Option>
    //   <Option value="Option 3">Option 3</Option>
    //   <Option value="Option 4">Option 4</Option>
    //   <Option value="Option 5">Option 5</Option>
    // </Select>

    
  );
};
export default Filter;
