import { SET_SERVICE_DETAILS , SET_ITEMS , CLEAR } from "redux/constants/AddQuotation";

export const setServiceDetails = (serviceDetails) => {
  return {
    type: SET_SERVICE_DETAILS,
    serviceDetails,
  }
}

export const setItems = (quotationItems) => {
  return{
    type: SET_ITEMS,
    quotationItems,
  }
}

export const clearData = () => {
  return{
    type: CLEAR,
  }
}
