import { Button, Card, Typography, List } from "antd";
import Icon from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import profilePic from "assets/Ellipse-21.png";
import { AccoutnNumerIcon, BirthdayIcon, MobileIcon, ExploreIcon, MailIcon } from "assets/svg/icon";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

import {
  setEditingMode,
  updateBasicDetails,
  addAddress,
  setId,
  setPrimaryAccount,
  setSecondaryAccount,
  clearAccountDetails,
  setProfilePic,
} from "redux/actions";
import { useHistory } from "react-router-dom";

const { Title, Text } = Typography;

const Profile = ({userData , url}) => {
  console.log(url);
  const { primaryAccount, secondaryAccount, haveSecondaryAccount, primaryId, secondaryId } = useSelector((state) => state.accountDetails);
  console.log(useSelector((state) => state));
  
  const dispatch = useDispatch();
  const history = useHistory();

  const dataAccountDetails = [
    { icon: AccoutnNumerIcon, text: `HC-${primaryId}` },
    { icon: BirthdayIcon, text: moment(primaryAccount.dob).format("DD/MM/YYYY") },
  ];

  const dataContactDetails = [
    { icon: MobileIcon, text: primaryAccount.phone },
    { icon: ExploreIcon, text: primaryAccount.address },
    { icon: MailIcon, text: primaryAccount.email },
  ];

  const dataSecondaryAccount = [
    { icon: AccoutnNumerIcon, text: secondaryAccount?.fullname },
    { icon: MobileIcon, text: secondaryAccount?.phone },
    { icon: ExploreIcon, text: secondaryAccount?.address },
    { icon: MailIcon, text: secondaryAccount?.email },
  ];
  const Imgstyles = {
    width: '130px',
    height: '130px',
    objectFit: 'cover',
    borderRadius:"50%"
  };
  
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <img style={Imgstyles} src={url} alt="..."/>
        <Title>{primaryAccount.fullname}</Title>
        {/* {JSON.stringify(primaryAccount)} */}
        {/* <Link to={`/app/account-management/edit-new-account?id=${userData.id}`}> */}
        <Button onClick={async () => {
          let id = userData.id;
           console.log("id",id);
           try {
             id = id.slice(3);
       
             const res = await axios.get(
               `/customers/get-customer/${id}`
             );
       
             if (!res.data.success) {
               throw new Error(res.data.data);
             }
       
             const editCustomer = res.data.data;
             console.log(editCustomer);
             const { fullname, email, phone, dob, gender,profile_pic } = editCustomer;
       
             const basicDetails = {
               fullname,
               email,
               phone,
               dob,
               gender,
               profile_pic
             };
             let addresses = [];
       
             if (!+editCustomer.addresses.length == 0) {
               addresses = editCustomer.addresses.map((addr) => {
                 return {
                   block_no: addr.block_no,
                   unit_no: addr.unit_no,
                   level_no: addr.level_no,
                   pin_code: addr.pin_code,
                   street_name: addr.street_name,
                   country_id: addr.country_id,
                 };
               });
             }
       
             dispatch(setEditingMode(true));
             dispatch(setProfilePic(basicDetails.profile_pic))
             dispatch(updateBasicDetails(basicDetails));
             dispatch(addAddress(addresses));
             dispatch(setId(id));
           } catch (err) {
             console.log(err);
           } finally {
             history.push("/app/account-management/edit-new-account?id=" + id);
           }
        }}>Edit Profile</Button>
        {/* </Link> */}
      </div>

      <List
        header={<Title level={3}>Account Details</Title>}
        dataSource={dataAccountDetails}
        renderItem={(item) => (
          <List.Item>
            <div className="d-flex justify-content-around">
              <Icon component={item.icon} className="mr-3" />
              {item.text}
            </div>
          </List.Item>
        )}
      />

      <List
        header={<Title level={3}>Contact</Title>}
        dataSource={dataContactDetails}
        renderItem={(item) => (
          <List.Item>
            <div className="d-flex justify-content-around">
              <Icon component={item.icon} className="mr-3" />
              {item.text}
            </div>
          </List.Item>
        )}
      />
      {haveSecondaryAccount && (
        <List
          header={<Title level={3}>Secondary Account</Title>}
          dataSource={dataSecondaryAccount}
          renderItem={(item) => (
            <List.Item>
              <div className="d-flex justify-content-around">
                <Icon component={item.icon} className="mr-3" />
                {/* <UserOutlined /> */}
                {item.text}
              </div>
            </List.Item>
          )}
        />
      )}
      </>
    
  );
};

export default Profile;
