import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Countries from './Countries';
import customerForm from './CustomerForm';
import AccountDetails from './AccountDetails'
import AddQuotation from './AddQuotation';
import Customers from './Customers';
import Gst from './Gst';
import Chat from './Chat'

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    countries: Countries,
    customerForm: customerForm,
    accountDetails: AccountDetails,
    addQuotation: AddQuotation,
    customers: Customers,
    gst: Gst,
    detailChat:  Chat
});

export default reducers;