export const BasicDetailsIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9824 15.0869C12.2598 15.0781 10.9238 13.6367 10.9238 11.7295C10.9062 9.93652 12.2686 8.44238 13.9824 8.44238C15.6963 8.44238 17.041 9.93652 17.041 11.7295C17.041 13.6367 15.7051 15.1045 13.9824 15.0869ZM13.9824 21.2393C12.0137 21.2393 9.92188 20.4219 8.59473 18.998C9.60547 17.4863 11.6094 16.6074 13.9824 16.6074C16.3291 16.6074 18.3506 17.4688 19.3701 18.998C18.043 20.4219 15.9512 21.2393 13.9824 21.2393Z" fill="#455560"/>
</svg>

);

export const BankDetailsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1904)">
<path d="M6.5 10.0012H4.5V17.0012H6.5V10.0012ZM12.5 10.0012H10.5V17.0012H12.5V10.0012ZM21 19.0012H2V21.0012H21V19.0012ZM18.5 10.0012H16.5V17.0012H18.5V10.0012ZM11.5 3.26122L16.71 6.00122H6.29L11.5 3.26122ZM11.5 1.00122L2 6.00122V8.00122H21V6.00122L11.5 1.00122Z" fill="#455560"/>
</g>
<defs>
<clipPath id="clip0_467_1904">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export const AddressDetaisIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1924)">
      <path
        d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1924">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AppAccessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1916)">
      <path
        d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 21H7V20H17V21ZM17 18H7V6H17V18ZM17 4H7V3H17V4ZM16 10.05L14.59 8.64L11.05 12.18L9.64 10.77L8.23 12.18L11.05 15L16 10.05Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1916">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EmploymentDetailsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1930)">
      <path
        d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3ZM18.82 9L12 12.72L5.18 9L12 5.28L18.82 9ZM17 15.99L12 18.72L7 15.99V12.27L12 15L17 12.27V15.99Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1930">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const UploadDocumentsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_467_1910)">
  <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#455560"/>
  </g>
  <defs>
  <clipPath id="clip0_467_1910">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  
);

export const BasicDetailsActiveIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9824 15.0869C12.2598 15.0781 10.9238 13.6367 10.9238 11.7295C10.9062 9.93652 12.2686 8.44238 13.9824 8.44238C15.6963 8.44238 17.041 9.93652 17.041 11.7295C17.041 13.6367 15.7051 15.1045 13.9824 15.0869ZM13.9824 21.2393C12.0137 21.2393 9.92188 20.4219 8.59473 18.998C9.60547 17.4863 11.6094 16.6074 13.9824 16.6074C16.3291 16.6074 18.3506 17.4688 19.3701 18.998C18.043 20.4219 15.9512 21.2393 13.9824 21.2393Z" fill="#0099A8"/>
</svg>

);

export const BankDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1790)">
<path d="M6.5 10.0012H4.5V17.0012H6.5V10.0012ZM12.5 10.0012H10.5V17.0012H12.5V10.0012ZM21 19.0012H2V21.0012H21V19.0012ZM18.5 10.0012H16.5V17.0012H18.5V10.0012ZM11.5 3.26122L16.71 6.00122H6.29L11.5 3.26122ZM11.5 1.00122L2 6.00122V8.00122H21V6.00122L11.5 1.00122Z" fill="#0099A8"/>
</g>
<defs>
<clipPath id="clip0_467_1790">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export const AddressDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1848)">
<path d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z" fill="#0099A8"/>
</g>
<defs>
<clipPath id="clip0_467_1848">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export const AppAccessActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1743)">
<path d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 21H7V20H17V21ZM17 18H7V6H17V18ZM17 4H7V3H17V4ZM16 10.05L14.59 8.64L11.05 12.18L9.64 10.77L8.23 12.18L11.05 15L16 10.05Z" fill="#0099A8"/>
</g>
<defs>
<clipPath id="clip0_467_1743">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export const EmploymentDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1892)">
<path d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3ZM18.82 9L12 12.72L5.18 9L12 5.28L18.82 9ZM17 15.99L12 18.72L7 15.99V12.27L12 15L17 12.27V15.99Z" fill="#0099A8"/>
</g>
<defs>
<clipPath id="clip0_467_1892">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export const UploadDocumentsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_1758)">
<path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#0099A8"/>
</g>
<defs>
<clipPath id="clip0_467_1758">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);