import React from "react";

import { Badge, Button, List, Typography } from "antd";
import Icon from "@ant-design/icons";

import {
  AssignedIcon,
  ClosedIcon,
  EmailIcon,
  InboxIcon,
  UnassignedIcon,
  WhatsappIcon,
} from "./svgIcons";
import { ReloadIcon } from "../../svgIcons";

const { Title } = Typography;

const AllInBox = ({ inquiries, setInboxType, getChatList, inboxType }) => {
  const data = [
    {
      icon: InboxIcon,
      text: "All",
      badgeNumber: inboxType === "All" ? inquiries.length : 0,
    },
    {
      icon: AssignedIcon,
      text: "Assigned",
      badgeNumber: inboxType === "Assigned" ? inquiries.length : 0,
    },
    {
      icon: UnassignedIcon,
      text: "Unassigned",
      badgeNumber: inboxType === "Unassigned" ? inquiries.length : 0,
    },
    {
      icon: WhatsappIcon,
      text: "Whatsapp",
      badgeNumber: inboxType === "Whatsapp" ? inquiries.length : 0,
      hasReload: true,
    },
    {
      icon: EmailIcon,
      text: "Email",
      badgeNumber: inboxType === "Email" ? inquiries.length : 0,
    },
    {
      icon: ClosedIcon,
      text: "Closed",
      badgeNumber: inboxType === "Closed" ? inquiries.length : 0,
    },
  ];

  const handleReloadClick = () => {};

  return (
    <>
      <List
        header={<Title level={3}>All Inbox</Title>}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <div className="w-100">
              {/* <div> */}
              {/* <div className="d-flex justify-content-between w-100 align-items-center">
                    <div className="d-flex align-items-center"> */}
              <Button
                className="w-100 d-flex align-items-center p-1"
                onClick={() => {
                  getChatList(item.text);
                  setInboxType(item.text);
                }}
              >
                <Icon component={item.icon} className="" />
                <span className="ml-2"> {item.text}</span>
                <Badge
                  className="ml-2"
                  count={item.badgeNumber}
                  style={{ background: "#0099A8" }}
                />
                {/* {item.hasReload && ( */}
                {/* <ReloadIcon style={{cursor:'pointer', width: '20px', zIndex:'999'}} onClick={handleReloadClick} /> // Render the reload icon if hasReload is true */}
                {/* )} */}
              </Button>
              {/* </div> */}
              {/* <Badge count={item.badgeNumber} style={{background: "#0099A8"}} /> */}
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

export default AllInBox;
