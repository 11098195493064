import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button, notification } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import notificationData from "assets/data/notification.data.json";
import Flex from 'components/shared-components/Flex'
import Icon from "@ant-design/icons";
import { SearchIcon, notificationIcon, settingsIcon } from "assets/svg/DashboardSvg";
import axios from 'axios';
import ChatRight from 'views/app-views/InquiryManagement/InqueryChats/ChatRight/ChatRight';
import ChatLayout from 'views/app-views/InquiryManagement/InqueryChats/ChatLayout';
import { useNotification } from '../../NotificationContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const getIcon = icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}

// const redirectToChat = (customerId) => {
//   setCustomerId(customerId);

// }



// const getNotificationBody = (list) => {
//   return list.length > 0 ?
//     <>
//       <List
//         size="small"
//         itemLayout="horizontal"
//         dataSource={list}
//         renderItem={item => (

//           <List.Item className="list-clickable" onClick={() => setCustomerId(item.chat_id)} key={item.chat_id}>
//             {console.log(item)}
//             <Icon className="mr-3" component={notificationIcon} />
//             <Flex alignItems="center">
//               <div className="pr-3">
//                 {/* {item[0].img ? <Avatar src={`/img/avatars/${item[0].img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />} */}
//               </div>
//               <div className="mr-3">
//                 <span className="font-weight-bold text-dark">{item.messenger_name}</span>
//                 <div className="text-gray-light">Sent You Message</div>
//               </div>
//               <small className="ml-auto">3:12 PM</small>
//             </Flex>
//           </List.Item>
//         )}
//       />
//     </>

//     :
//     <div className="empty-notification">
//       <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
//       <p className="mt-3">You have viewed all notifications</p>
//     </div>;
// }

export const NavNotification = ({ setCustomerId, }) => {
  const { setNotificationData, setCustomersId, setInboxType, setUserName } = useNotification();
  const [count, setCount] = useState(0);

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(notificationData);
  const [notifiData, setNotifiData] = useState([]);
  // <ChatLayout notifiData={notifiData} setNotifiData={setNotifiData}/>
  const dataNotifi = async () => {
    try {
      const res = await axios.get("/fcm/Inquires/get-all-unread-messages/132");

      setNotifiData(res.data.data);
      setNotificationData(res.data.data);
      setCount(res.data.data.length);
    }
    catch (err) {
      console.error('Error fetching notification data:', err);
    }
  }

  useEffect(() => {
    // dataNotifi();

    // Fetch data initially when component mounts
    dataNotifi();

    // Call fetchData every 3 minutes
    const intervalId = setInterval(dataNotifi, 1 * 60 * 1000);

    // Clean up function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [])

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button className="text-primary" type="link" onClick={() => setData([])} size="small">Clear </Button>
      </div>
      <div className="nav-notification-body">
        {/* {notifiData.map((item) => console.log(item))} */}
        {/* {getNotificationBody(notifiData)}*/}


        <List
          size="small"
          itemLayout="horizontal"
          dataSource={notifiData}
          renderItem={item => (

            <List.Item
              className="list-clickable"
              onClick={() => {
                setCustomersId(item.chat_id);
                setInboxType("All");
                setUserName(item.User_Name)

              }}
              key={item.chat_id}
            >
              <Link to="/app/inquiry-management/chats">
                <Icon className="mr-3" component={notificationIcon} />
                <Flex alignItems="center">
                  <div className="pr-3">
                    {/* {item[0].img ? <Avatar src={`/img/avatars/${item[0].img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />} */}
                  </div>
                  <div className="mr-3">
                    <span className="font-weight-bold text-dark">{item.messenger_name}</span>
                    <div className="text-gray-light">Sent You Message</div>
                  </div>
                  <small className="ml-auto">3:12 PM</small>
                </Flex>
              </Link>
            </List.Item>
          )}
        />

      </div>
      {
        console.log(notifiData.length)
      }
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={count}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}


export default NavNotification;
