import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notificationData, setNotificationData] = useState([]);
    const [customersId, setCustomersId] = useState();
    const [userName, setUserName] = useState();
    const [inboxType, setInboxType] = useState("All");
    return (
        <NotificationContext.Provider value={{ notificationData, setNotificationData, customersId, setCustomersId, inboxType, setInboxType, userName, setUserName }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
