import React, { useEffect, useState } from "react";

import { Card, Typography, Row, Col, Modal } from "antd";
import Loading from "components/shared-components/Loading";
import profilePic from "assets/PrimaryAccount.png";

import "./PrimaryAccount.css";
import axios from "axios";
import moment from "moment";
const { Title, Text } = Typography;

const PrimaryAccount = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [primaryCustomer, setPrimaryCustomer] = useState(null);
  const [customerAddress, setCustomerAddress] = useState("N.A.");

  const { customerId } = props;

  useEffect(() => {
    const getCustomer = async () => {
      const res = await axios.get(
        `/customers/get-customer/${customerId}`
      );

      const customer = res.data.data;
      let _address = null;

      if (customer?.addresses && customer?.addresses.length > 0) {
        _address = customer?.addresses[0];
      }
      delete customer.addresses;

      if (_address) {
        const { level_no, block_no, street_name, country_name } = _address;
        setCustomerAddress(`${level_no}, ${block_no}, ${street_name}, ${country_name}`);
      }
      const { id, fullname, email, phone, gender, dob, age_group } = customer;
      const _customer = {
        ...customer,
        id,
        fullname,
        email,
        phone,
        gender,
        dob: moment(dob).format("DD/MM/YYYY"),
        age_group: age_group ? age_group : 'N.A.',
      };

      setPrimaryCustomer(_customer);
    };
    try {
      setIsLoading(true);
      getCustomer();
    } catch (err) {
      Modal.error({
        title: "ERROR",
        content: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  console.log(primaryCustomer, customerAddress);
  return (
    <>
      
        <Card style={{ background: "#FAFAFB" , height: "600px" }}>
          {isLoading && <Loading />}
          {!isLoading && !primaryCustomer && <p>No Data</p>}
          {!isLoading && primaryCustomer && (
            <div className="d-flex flex-column text-center w-100">
              <div className="mb-3 mt-16">
                <img style={{
                  width: "150px",
                  height: "150px",
                }} src={primaryCustomer?.profile_pic} className="rounded-circle "></img>
              </div>

              <Text strong>{primaryCustomer.fullname}</Text>
              <Text className="mb-4">{`HC-${primaryCustomer.id}`}</Text>

              <Row>
                <Col span={11}>
                  <div className="d-flex flex-column text-left mb-4">
                    <Text secondry className="mb-1">
                      Email Address
                    </Text>
                    <Text strong>{primaryCustomer.email}</Text>
                  </div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <div className="d-flex flex-column text-left mb-4">
                    <Text secondry className="mb-1">
                      Phone Number
                    </Text>
                    <Text strong>{primaryCustomer.phone}</Text>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <div className="d-flex flex-column text-left mb-4">
                    <Text secondry className="mb-1">
                      Address
                    </Text>
                    <Text strong>{customerAddress}</Text>
                  </div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <div className="d-flex flex-column text-left gender">
                    <Text secondry className="mb-1">
                      Gender
                    </Text>
                    <Text strong>{primaryCustomer.gender}</Text>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <div className="d-flex flex-column text-left mb-4">
                    <Text secondry className="mb-1">
                      Birth Date
                    </Text>
                    <Text strong>{primaryCustomer.dob}</Text>
                  </div>
                </Col>
                <Col span={2}></Col>
             
              </Row>
              <Row>
                <Col span={11}>
                  <div className="d-flex flex-column text-left mb-4">
                    <Text secondry className="mb-1">
                      Total Orders
                    </Text>
                    <Text strong>{primaryCustomer.total_orders}</Text>
                  </div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <div className="d-flex flex-column text-left mb-4">
                    <Text secondry className="mb-1">
                      Total Inquiry
                    </Text>
                    <Text strong>{primaryCustomer.total_inquiries}</Text>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      
    </>
  );
};

export default PrimaryAccount;
