import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Typography, Modal } from "antd";

import axios from "axios";
import Icon from "@ant-design/icons";

import UserManagementIcon from "../../../../assets/svg/usermManagementPage.svg";
import PageHeading from "components/shared-components/PageHeading/PageHeading";
import SearchBox from "../../../../components/shared-components/SearchBox";
import filterIcon from "../../../../assets/svg/filterIcon.svg";
import exportIcon from "../../../../assets/svg/exportIcon.svg";
import plusIcon from "../../../../assets/svg/plus.svg";
import AccountListTable from "./AccountListTable";
// import Actions from "./Actions";
import Filter from "../../../../components/shared-components/Filter";
// import Modal from "components/UI/Modal";
import Export from "./Export-AccountList";
import { Link, useRouteMatch } from "react-router-dom";
import Drawer from "react-modern-drawer";

import { MergeIcon, FileExportIcon } from "assets/svg/icon";
import "react-modern-drawer/dist/index.css";
import MergeDrawer from "./MergeDrawer";
import Merge from "./Merge";

import classes from "./AccountList.module.css";
// import { getStatusClassNames } from "antd/lib/_util/statusUtils";
import { CSVLink } from "react-csv";
import Csv from "views/app-views/Csv";

const { Title, Text } = Typography;

const AccountList = () => {
  const match = useRouteMatch();
  const [dataToDownload, setDataToDownload] = useState([]);
  const csvDownloadRef = useRef(null);
  const [page, setPage] = useState(1);
  console.log(page)

  const [showExportOption, setShowExportOption] = useState(false);

  const [showMergeModal, setShowMergeModal] = useState(false);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [loadedCustomers, setLoadedCustomers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  // const getCsv = async() => {
  //   try {
  //     //  const res = await axios.get(`/users/export-user-list-csv/1`);

  //     const res = await axios({
  //       url: `/customers/export-customer-list-csv`, //your url
  //       method: "GET",
  //       responseType: "blob", // important
  //     });
  //     // create file link in browser's memory
  //     const href = URL.createObjectURL(res.data);

  //     // create "a" HTML element with href to file & click
  //     const link = document.createElement("a");
  //     link.href = href;

  //     link.setAttribute("download", "customer-data.csv"); //or any other extension
  //     document.body.appendChild(link);
  //     link.click();

  //     // clean up "a" element & remove ObjectURL
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(href);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // }



  const fetchDataToDownload = () => {
    axios.get("customers/get-all-customers?searchTerm=").then(({ data }) => {
      setDataToDownload(data);
      console.log(data);
      csvDownloadRef.current.link.click();

    })
      .catch((error) => alert("Error Message"))
  }

  const exportHandler = () => {
    // setShowExportOption((prev) => !prev);
    Modal.confirm({
      title: (
        <div className="d-flex flex-column align-items-center">
          <Icon component={FileExportIcon} className="mb-3" />
          <Title level={3}>Export/Download Data</Title>
        </div>
      ),
      content: <div style={{ textAlign: 'center' }}>
        <start>Do you want to Export Customers List?</start>
      </div>,
      icon: null,
      confirmLoading: true,
      //  onOk: getCsv
    });
  };



  const drawerHandler = () => {
    setDrawerIsOpen((prevState) => !prevState);
  };

  const mergeHandler = () => {
    setShowMergeModal((prev) => !prev);
  };

  const exportModal = (
    <Modal onclose={exportHandler}>
      <Export onClose={exportHandler} />
    </Modal>
  );

  const mergeModal = (
    <Modal onclose={mergeHandler}>
      <Merge onClose={mergeHandler} />
    </Modal>
  );

  const getCustomers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/customers/get-all-customers?pageSize=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchText}`
      );
      // console.log(response.data.data);
      if (!response.data.success) {
        throw new Error(response.data || "Something went Wrong");
      }

      const customers = response.data.data.
        finalData;

      setPageSize(response.data.data.pagination.pageSize);
      setPageNumber(response.data.data.pagination.pageNumber);
      setTotalSize(response.data.data.pagination.totalSize);
      // console.log(customers);
      setLoadedCustomers(customers);

      // console.log(response.data.data);
    } catch (err) {
      Modal.error({
        title: "Can't Load Customers",
        content: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log("REACT_APP_BACKEND_URL", process.env.REACT_APP_BACKEND_URL);

    getCustomers(page);
  }, []);

  useEffect(() => {
    getCustomers();
  }, [searchText, pageNumber])



  const header = [
    { label: "Id", key: "id" },
    { label: "Customer Name", key: "fullname" },
    { label: "Phone Number", key: "phone" },
    { label: "Email Id", key: "email" },
    { label: "Total Revenue", key: "total_revenue" },
    { label: "Outstanding Amount", key: "outstanding_amount" }
  ]

  const data = loadedCustomers.map(item => ({
    id: `HC-${item.id}`,
    fullname: item.fullname,
    phone: item.phone,
    email: item.email,
    total_revenue: item.total_revenue,
    outstanding_amount: item.outstanding_amount
  }))



  useEffect(() => {


  }, [loadedCustomers])


  return (
    <React.Fragment>
      <Drawer
        open={drawerIsOpen}
        onClose={drawerHandler}
        direction="right"
        zIndex={1000}
        size={950}
        className={`${classes.drawer}`}
      >
        <MergeDrawer
          onClose={drawerHandler}
          toggleModal={mergeHandler}
          loadedCustomers={loadedCustomers}
        />
      </Drawer>

      {/* {showExportOption && exportModal} */}
      {showMergeModal && mergeModal}
      {/* Heading */}
      <div>
        <PageHeading icon={UserManagementIcon} title="Account Management / Account List" />
      </div>

      {/* buttons */}
      <div className="d-flex justify-content-between mb-3">
        <div className=" d-flex align-items-center justify-content-between">
          <SearchBox
            placeholder='Search'
            // onSearch={(text) => setSearchText(text)}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {/* <Filter>
            <Button className="d-flex align-items-center ml-2">
              <img className="mr-2" src={filterIcon} alt="filterIcon" />
              Filters
            </Button>
          </Filter> */}

          <Csv header={header} data={data} filename={"Account.csv"} />



          <Button onClick={drawerHandler} className="d-flex align-items-center ml-2">
            <Icon component={MergeIcon}></Icon>Merge
          </Button>
        </div>
        <div>
          <Link to="/app/account-management/add-new-account">
            <Button className="d-flex align-items-center" type="primary" size="large">
              <img className="mr-2" src={plusIcon} alt="plusIcon"></img>
              Add new
            </Button>
          </Link>
        </div>
      </div>

      {/* table */}
      <div>
        <AccountListTable
          searchText={searchText}
          loadedCustomers={loadedCustomers}
          setLoadedCustomers={setLoadedCustomers}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setPage={setPage}
          getCustomers={getCustomers}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          totalSize={totalSize}
          pageSize={pageSize}
          pageNumber={pageNumber}
        />
      </div>
    </React.Fragment>
  );
};

export default AccountList;