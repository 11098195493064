import {
  Avatar,
  Button,
  Divider,
  Form,
  Image,
  Input,
  Tooltip,
  Upload,
} from "antd";
import React from "react";
import axios from "axios";

import { useState, useEffect } from "react";
import "./Bottom.css";
import Icon, {
  AudioOutlined,
  FileOutlined,
  LoadingOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  DocFileIcon,
  MicIcon,
  PhotoIcon,
  SendIcon,
  VideoIcon,
} from "assets/svg/icon";
import moment from "moment";
import { SendMessageSocket } from "./SendMessageSocket";
import InfiniteScroll from "react-infinite-scroller";
import { socket } from "services/Socket";
import TextArea from "antd/lib/input/TextArea";
import { UploadApi } from "./UploadApi";

function Bottom({ customerId, loadCount, type }) {
  const [messageInput, setMessageInput] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileForVideo, setFileForVideo] = useState([]);
  const [fileForDocument, setFileForDocument] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [fileForAudio, setFileForAudio] = useState([]);

  const handleSendChat = async () => {
    setLoadings(true);
    if (messageInput.length >= 1) {
      SendMessageSocket(customerId, messageInput);
      setLoadings(false);
      return;
    } else if (fileList.length !== 0) {
      const res = await UploadApi(fileList[0]);
      if (res) {
        SendMessageSocket(customerId, res, "image");
      }
      setLoadings(false);
      return;
    } else if (fileForVideo.length !== 0) {
      const res = await UploadApi(fileForVideo[0]);
      if (res) {
        SendMessageSocket(customerId, res, "video");
      }
      setLoadings(false);
      return;
    } else if (fileForDocument.length !== 0) {
      const res = await UploadApi(fileForDocument[0]);
      if (res) {
        SendMessageSocket(customerId, res, "document");
      }
      setLoadings(false);
      return;
    }
    else if (fileForAudio.length !== 0) {
      const res = await UploadApi(fileForAudio[0]);
      if (res) {
        SendMessageSocket(customerId, res, "audio");
      }
      setLoadings(false);
      return;
    }
  };

  useEffect(() => {
    socket.on("message", (data) => {
      console.log("message event");
      console.log(data);
      console.log(customerId);
      if (data.messageData.chat_id === customerId.chat_room_id) {
        setChatMessages((prev) => [data.messageData, ...prev]);
      }
      setMessageInput("");
      setFileList([]);
      setFileForVideo([]);
      setFileForDocument([]);
      setFileForAudio([]);
    });
    return () => {
      socket.off("message");
    };
  }, [customerId]);

  const getAllChatMessages = async () => {
    if (customerId) {
      const response = await axios.get(
        `/socket/inquires/get-chat-history/${customerId.chat_room_id ? customerId.chat_room_id : customerId}`
      );

      setChatMessages(response.data.data);
    }
  };

  const sendMessage = async () => {
    console.log(messageInput.trim());
    if (messageInput.trim() !== "") {
      try {
        let data = {
          message: messageInput,
        };
        console.log(data);
        const response = await axios.post(`/whatsapp/sendmsg`, data);
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          const responseData = response.data;
          console.log("Response Data:", responseData);
          const newMessage = {
            message: messageInput,
            sender: "HC",
            created_at: responseData.created_at,
          };
          setChatMessages([...chatMessages, newMessage]);
          setMessageInput("");
        } else {
          console.error(
            "Request was not successful. Status code:",
            response.status
          );
        }
        return true;
      } catch (err) {
        return false;
      }
    }
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault(); // Prevent form submission on Enter
  //     SendMessageSocket(customerId); // Call sendMessage when Enter is pressed
  //   }
  // };

  useEffect(() => {
    getAllChatMessages();
    setMessageInput("");
    setFileList([]);
    setFileForVideo([]);
    setFileForDocument([]);
    setFileForAudio([]);
  }, [customerId]);

  const propsForImage = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const propsForAudio = {
    onRemove: (file) => {
      setFileForAudio([]);
    },
    beforeUpload: (file) => {
      setFileForAudio([file]);
      return false;
    },
    fileList: fileForAudio,
  }

  const propsForVideo = {
    onRemove: (file) => {
      setFileForVideo([]);
    },
    beforeUpload: (file) => {
      setFileForVideo([file]);
      return false;
    },
    fileList: fileForVideo,
  };

  const propsForDocument = {
    onRemove: (file) => {
      setFileForDocument([]);
    },
    beforeUpload: (file) => {
      setFileForDocument([file]);
      return false;
    },
    fileList: fileForDocument,
  };

  return (
    <div className="BottomChatsRight" style={{ paddingBottom: "100px" }}>
      {/* <div className='chatBody'> */}
      {/* <Divider><span className='text-primary font-size-sm font-weight-bold'>Yesterday</span></Divider> */}
      {/* <div className='BottomRightMsgContainer'>
          <Avatar className='ProfileIconChatBody' size='large' src="http://enlink.themenate.net/assets/images/avatars/thumb-3.jpg" />
          <div className='BottomRightMsgDetails'>
            <p className='BottomRightMsg' >Hello !</p>
            <p className='BottomRightTime' >9:00</p>
          </div>
        </div> */}

      {/* <div className={`BottomRightMsgContainer ${myId === senderId && 'myMsg'} `}>
          {myId !== senderId && <Avatar className='ProfileIconChatBody' size='large' src="http://enlink.themenate.net/assets/images/avatars/thumb-3.jpg" />}
          <div className={`BottomRightMsgDetails ${myId === senderId && 'myMsg'}`}>
            <p className={`BottomRightMsg ${myId === senderId && 'myMsg'} `} >Loreum ipsum is simply dummy text of the printing and typesetting industry.</p>
            <p className={`BottomRightTime ${myId === senderId && 'myMsg'} `} >9:00</p>
          </div>
        </div> */}

      {/* <div className='BottomRightMsgContainer'>
          <Avatar className='ProfileIconChatBody' size='large' src="http://enlink.themenate.net/assets/images/avatars/thumb-3.jpg" />
          <div className='BottomRightMsgDetails'>
            <p className='BottomRightMsg' >Loreum ipsum is simply dummy text of the printing and typesetting industry.</p>
            <p className='BottomRightTime' >9:00</p>
          </div>
        </div> */}

      <div
        id="scrollableDiv"
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          height: 300,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
        className="chatBody"
      >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
          dataLength={chatMessages.length}
          // next={this.fetchMoreData}
          style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
          inverse={true} //
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {chatMessages.map((message, index) => {
            const myMsg = message.user_id === null ? true : false;

            return (
              <div
                key={index}
                className={`BottomRightMsgContainer 'd-flex flex-column justify-content-end ${myMsg ? "myMsg align-items-end" : "align-items-start"
                  } `}
              >
                <div
                  className={`d-flex flex-column justify-content-end ${myMsg ? "align-items-end" : "align-items-start"
                    }`}
                >
                  {
                    myMsg ? (
                      <div style={{
                        fontSize: "10px"
                      }}>{message.messenger_name}</div>
                    ) :
                      (
                        <div style={{
                          fontSize: "10px"
                        }}>{message.messenger_name}</div>

                      )
                  }
                  {!message.media_file ? (
                    <p className={`BottomRightMsg ${myMsg && "myMsg"} `}>
                      {message.chat_message}
                    </p>
                  ) : (
                    <>
                      {message.chat_message_type === "image" && (
                        <div className={`BottomRightMsg ${myMsg && "myMsg"} `}>
                          <Image
                            style={{ maxWidth: "300px", minWidth: "60px" }}
                            src={message.media_file}
                          />
                        </div>
                      )}
                      {message.chat_message_type === "video" && (
                        <p className={`BottomRightMsg ${myMsg && "myMsg"} `}>
                          <a
                            style={{ textDecoration: "none", color: "#272C35" }}
                            href={message.media_file}
                          >
                            <VideoCameraOutlined /> Click to View
                          </a>
                        </p>
                      )}
                      {message.chat_message_type === "document" && (
                        <p className={`BottomRightMsg ${myMsg && "myMsg"} `}>
                          <a
                            style={{ textDecoration: "none", color: "#272C35" }}
                            href={message.media_file}
                          >
                            <FileOutlined /> Click to View
                          </a>
                        </p>
                      )}
                      {
                        message.chat_message_type === "audio" && (
                          <p className={`BottomRightMsg ${myMsg && "myMsg"} `}>
                            {/* <audio controls src={message.media_file} /> */}

                            <a
                              style={{ textDecoration: "none", color: "#272C35" }}
                              target="_blank"
                              href={message.media_file}
                            >
                              <AudioOutlined /> Click to View
                            </a>
                          </p>
                        )
                      }
                    </>
                  )}

                  <p className={`BottomRightTime ${myMsg && "myMsg"} `}>
                    {message.created_at
                      ? moment(message.created_at).format("DD MMM YYYY hh:mm A")
                      : ""}
                  </p>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
      {/* </div> */}
      {type !== "Closed" && <div className="" style={{ padding: "0 30px 0 20px" }}>
        <Form>
          <TextArea
            rows={2}
            style={{ borderRadius: "8px" }}
            // className="ChatBodyFormContainerInput"
            placeholder="Type a message..."
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          // onKeyPress={handleKeyPress}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // padding: "0px 15px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              <div style={{ paddingTop: "20px" }}>
                <Upload
                  // action={}
                  {...propsForImage}
                  accept="image/*"
                >
                  <Tooltip placement="top" title="Upload Image">
                    <Button
                      icon={<Icon component={PhotoIcon} />}
                      style={{
                        border: "none",
                        position: "absolute",
                        top: "4px",
                        left: "-2px",
                      }}
                      disabled={
                        fileForAudio.length !== 0 ?
                          true :
                          fileForDocument.length !== 0
                            ? true
                            : fileList.length !== 0
                              ? true
                              : fileForVideo.length !== 0
                                ? true
                                : false
                      }
                    ></Button>
                  </Tooltip>
                </Upload>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Upload
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  {...propsForVideo}
                  // fileList={[...fileList]}
                  // onChange={handleChangeForVideo}
                  accept="video/*"
                // disabled={fileList.length===0 ?false:true}
                >
                  <Tooltip placement="top" title="Upload Video">
                    <Button
                      icon={<Icon component={VideoIcon} />}
                      style={{
                        border: "none",
                        position: "absolute",
                        top: "4px",
                        left: "40px",
                      }}
                      disabled={
                        fileForAudio.length !== 0 ?
                          true :
                          fileForDocument.length !== 0
                            ? true
                            : fileList.length !== 0
                              ? true
                              : fileForVideo.length !== 0
                                ? true
                                : false
                      }
                    ></Button>
                  </Tooltip>
                </Upload>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Upload {...propsForDocument} accept=".pdf,.doc,.docx,.csv">
                  <Tooltip placement="top" title="Upload Document">
                    <Button
                      icon={<Icon component={DocFileIcon} />}
                      style={{
                        border: "none",
                        position: "absolute",
                        top: "4px",
                        left: "80px",
                      }}
                      disabled={
                        fileForAudio.length !== 0 ?
                          true :
                          fileForDocument.length !== 0
                            ? true
                            : fileList.length !== 0
                              ? true
                              : fileForVideo.length !== 0
                                ? true
                                : false
                      }
                    ></Button>
                  </Tooltip>
                </Upload>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Upload {...propsForAudio} accept=".mp3">
                  <Tooltip placement="top" title="Upload Audio">
                    <Button
                      icon={<Icon component={MicIcon} />}
                      style={{
                        border: "none",
                        position: "absolute",
                        top: "4px",
                        left: "120px",
                      }}
                      disabled={
                        fileForAudio.length !== 0 ?
                          true :
                          fileForDocument.length !== 0
                            ? true
                            : fileList.length !== 0
                              ? true
                              : fileForVideo.length !== 0
                                ? true
                                : false
                      }
                    ></Button>
                  </Tooltip>
                </Upload>
              </div>
            </div>
            <Button
              loading={loadings}
              style={{ border: "none", cursor: "pointer", background: "none" }}
              onClick={handleSendChat}
              disabled={
                messageInput.length >= 1
                  ? false
                  : fileList.length !== 0
                    ? false
                    : fileForDocument.length !== 0
                      ? false
                      : fileForVideo.length !== 0
                        ? false
                        : fileForAudio.length !== 0 ? false : true
              }
            >
              <Icon component={SendIcon} />
            </Button>
          </div>
          {/* <div className="ChatBodyFormContainerIconsContainer">
            <div className="ChatBodyFormContainerIcons">
              <Icon component={PhotoIcon} />
              <Icon component={VideoIcon} />
              <Icon component={DocFileIcon} />
              <Icon component={MicIcon} />
            </div>
            <Icon
              style={{ cursor: "pointer" }}
              component={SendIcon}
              onClick={() => SendMessageSocket(customerId,messageInput)}
            />
          </div> */}
        </Form>
      </div>}
    </div>
  );
}

export default Bottom;
