import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
} from "antd";
import UploadProfilePic from "components/shared-components/UploadProfilePic";
import { useContext } from "react";
import { UserManagementFormContext } from "context/UserManagementFormContext";
const { RangePicker } = DatePicker;
const { TextArea } = Input;


const Form1 = () => {
  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const ctx = useContext(UserManagementFormContext);
  
  const dob = ctx.editFormData.dob?moment(ctx.editFormData?.dob , "YYYY/MM/DD"):null;
  
  console.log(ctx.editFormData);
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Profile Photo"
            name="profilePhoto"
            
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide your profile pic!",
            //   },
            // ]}
          >
            {/* <Upload
              action="/upload.do"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={true}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload> */}
            <UploadProfilePic/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Staff Id"
            name="staffId"
            rules={[{ required: true }]}
            initialValue={ctx.editFormData?.id || "HC 123456789"}
          >
            <Input   />
          </Form.Item>
          <Form.Item
            label="Email Id"
            name="emailId"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your email-id!",
              },
            ]}
            initialValue={ctx.editFormData?.email || null}
          >
            <Input  />
          </Form.Item>
          <Form.Item label="Age Group" name="ageGroup" initialValue={ctx.editFormData.age_group || null}>
            <Select >
              <Select.Option value="10-20">10-20</Select.Option>
              <Select.Option value="20-30">20-30</Select.Option>
              <Select.Option value="30-40">30-40</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Gender" name="gender" initialValue={ctx.editFormData?.gender}>
            <Radio.Group >
              <Radio value="male"> Male </Radio>
              <Radio value="female"> Female </Radio>
              <Radio value="other"> Other </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Race" name="race" initialValue={_.toLower(ctx.editFormData?.race) || null}>
            <Select >
              <Select.Option value="asian">Asian</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              { whitespace: true, message: "Your Name Cannot be blank" },
              { min: 3, message: "Your name should be atleast 3 characters" },
            ]}
            initialValue={ctx.editFormData?.fullname || null}
          >
            <Input  />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
            initialValue={ctx.editFormData?.phone || null}
          >
            <Input maxLength={10}  />
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: "Please input your date of birth",
              },
            ]}
            initialValue={dob}
          >
            <DatePicker format="YYYY-MM-DD"   />
          </Form.Item>
          <Form.Item label="Martial Status" name="martialStatus" initialValue={ctx.editFormData?.marital_status ||null}>
            <Select >
              <Select.Option value="married">Married</Select.Option>
              <Select.Option value="unMarried">Un Married</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Religion" name="religion" initialValue={ctx.editFormData?.religion || null}>
            <Select >
              <Select.Option value="islamic">Islamic</Select.Option>
              <Select.Option value="hinduism">Hinduism</Select.Option>
              <Select.Option value="christianity">Christianity</Select.Option>
              <Select.Option value="buddhism">Buddhism</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default () => <Form1 />;
