import { SET_SERVICE_DETAILS , SET_ITEMS , CLEAR } from "redux/constants/AddQuotation";

const initialState = {
  customer_id: null,
  service_type: null,
  pickup_date: null,
  pickup_time: null,
  expiry_date: null,
  customer_request: null,
  customer_address_id: null,
  quotation_items: [],
};



const AddQuotation = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICE_DETAILS:
      return {
        ...state,
        ...action.serviceDetails
      };

    case SET_ITEMS:
      // const quotation_items = [...state.quotation_items , action.quotationItem];
      return{
        ...state,
        quotation_items: action.quotationItems,
      }

    case CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default AddQuotation;
