import React from "react";

import { Row, Col, Card } from "antd";

import Profile from "./Profile";
import FinanceTable from "./FinanceTable";

const TabContentFinance = ({userData, status, paymentType}) => {

  return (
    <Row gutter={20}>
      <Col span={6}>
        <Card style={{ height: 600 }} className="ScrollY">
          <Profile userData={userData===null ? "" : userData} url={userData===null ? "" : userData.profile_pic} />
        </Card>
      </Col>
      <Col span={18}>
        <Card>
          <FinanceTable status={status} paymentType={paymentType} />
        </Card>
      </Col>
    </Row>
  );
};

export default TabContentFinance;
