import React, { useState, useContext } from "react";
import { UserManagementFormContext } from "context/UserManagementFormContext";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
} from "antd";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Form4 = () => {
  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const ctx = useContext(UserManagementFormContext);
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Work permit number (if applicable)"
            name="workPermitNumber"
            initialValue={ctx.editFormData?.work_permit_number}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Type of Work Permit" name="typeOfWorkPermit">
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Passport Number"
            name="passwortNumber"
            initialValue={ctx.editFormData?.passport_number}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Expiry Date"
            name="expiryDateWorkPermit"
            initialValue={
              ctx.editFormData.work_permit_number
                ? moment(ctx.editFormData?.work_permit_number, "YYYY/MM/DD")
                : null
            }
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Form.Item
            label="Expiry Date"
            name="expiryDatePassportNumber"
            initialValue={
              ctx.editFormData.passport_exp_date
                ? moment(ctx.editFormData?.passport_exp_date, "YYYY/MM/DD")
                : null
            }
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default () => <Form4 />;
