import React, { useState, useEffect } from "react";

import { Col, Row, Tabs, Button, Menu, Checkbox } from "antd";
import Icon from "@ant-design/icons";

import Nav from "./Tabs";
import PageHeading from "components/shared-components/PageHeading/PageHeading";
import SearchBox from "components/shared-components/SearchBox";
import Filter from "components/shared-components/Filter";
import TabContent from "./TabContent";
import TabContentInqurieList from "./TabContentInqurieList";
import TabContentFinance from "./TabContentFinance";
import UserManagementIcon from "assets/svg/usermManagementPage.svg";
import {
  FinanceIcon,
  InquiryListIcon,
  ItemsAndServicesIcon,
  FilterIcon,
} from "assets/svg/icon";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import TabContentAddress from "./TabContentAddress";

// const icons = [
// <Icon component={ItemsAndServicesIcon}/>,
// <Icon component={InquiryListIcon}/>,
// <Icon component={FinanceIcon}/>,
// ]

const AccountDetails = () => {
  const [userData, setuserData] = useState(null);

  const history = useHistory();
  const getData = async (id) => {
    let res = await axios.get(`/customers/get-customer/${id}`);
    setuserData(res.data.data);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    // console.log(id);
    getData(id);
    console.log("test");
    window.onbeforeunload = (event) => {
      console.log(event);
      event.preventDefault();

      window.location.href = "/apps/account-management/account-list";
    };
  }, []);

  const [tab1FilterStatus, setTab1FilterStatus] = useState([]);
  const [tab1FilterServiceType, setTab1FilterServiceType] = useState([]);

  const [tab2FilterStatus, setTab2FilterStatus] = useState([]);
  const [tab2FilterServiceType, setTab2FilterServiceType] = useState([]);

  const [tab3FilterStatus, setTab3FilterStatus] = useState([]);
  const [tab3FilterPaymentType, setTab3FilterPaymentType] = useState([]);

  const [tab4FilterStatus, setTab4FilterStatus] = useState([])

  const [tabChange, setTabChange] = useState("1");


  useEffect(() => {
    console.log(tab1FilterServiceType);
  }, [tab1FilterServiceType]);

  const functionForMenu = () => {
    switch (tabChange) {
      case "1":
        return (
          <Menu mode="horizontal" >
            <Menu.SubMenu key="item1" title="Status" >
              <Menu.Item key="subitem1" disabled>
                <Checkbox
                  checked={tab1FilterStatus.length === 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([]);
                    } else {
                      setTab1FilterStatus([]);
                    }
                  }}
                >
                  All
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("pending collection")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "pending collection",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "pending collection"
                        )
                      );
                    }
                  }}
                >
                  Pending Collection
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem3" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("items collected")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "items collected",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter((el) => el !== "items collected")
                      );
                    }
                  }}
                >
                  Item Collected
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem4" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("pending assessment")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "pending assessment",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "pending assessment"
                        )
                      );
                    }
                  }}
                >
                  Pending Assessment
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem5">
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "item delivered",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter((el) => el !== "item delivered")
                      );
                    }
                  }}
                  checked={
                    tab1FilterStatus.includes("Item Delivered") ? true : false
                  }
                >
                  Item Delivered
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem6" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("pending service")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "pending service",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "pending service"
                        )
                      );
                    }
                  }}
                >
                  Pending Service
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem7" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("QC Passed")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "QC Passed",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "QC Passed"
                        )
                      );
                    }
                  }}
                >
                  QC Passed
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem10" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("QC Failed")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "QC Failed",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "QC Failed"
                        )
                      );
                    }
                  }}
                >
                  QC Failed
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem8" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("craftsman assigned")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "craftsman assigned",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "craftsman assigned"
                        )
                      );
                    }
                  }}
                >
                  Craftsman Assigned
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem11" disabled>
                <Checkbox
                  checked={tab1FilterStatus.includes("service completed")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterStatus([
                        ...tab1FilterStatus,
                        "service completed",
                      ]);
                    } else {
                      setTab1FilterStatus(
                        tab1FilterStatus.filter(
                          (el) => el !== "service completed"
                        )
                      );
                    }
                  }}
                >
                  Service Completed
                </Checkbox>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="item2" title="Service Type">
              <Menu.Item key="subitem1" disabled>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterServiceType([]);
                    } else {
                      setTab1FilterServiceType([]);
                    }
                  }}
                  checked={tab1FilterServiceType.length === 0 ? true : false}
                >
                  All
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2" disabled>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterServiceType([
                        ...tab1FilterServiceType,
                        "In House",
                      ]);
                    } else {
                      setTab1FilterServiceType(
                        tab1FilterServiceType.filter((el) => el !== "In House")
                      );
                    }
                  }}
                  checked={
                    tab1FilterServiceType.includes("In House") ? true : false
                  }
                >
                  In House
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem3" disabled>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab1FilterServiceType([
                        ...tab1FilterServiceType,
                        "onsite",
                      ]);
                    } else {
                      setTab1FilterServiceType(
                        tab1FilterServiceType.filter((el) => el !== "onsite")
                      );
                    }
                  }}
                  checked={
                    tab1FilterServiceType.includes("onsite") ? true : false
                  }
                >
                  Onsite
                </Checkbox>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        );

      case "2":
        return (
          <Menu mode="horizontal">
            <Menu.SubMenu key="item1" title="Status" >
              <Menu.Item key="subitem1" disabled>
                <Checkbox>All</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2" disabled>
                <Checkbox>Pending Collection</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem3" disabled>
                <Checkbox>Item Collected</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem4" disabled>
                <Checkbox>Pending Assessment</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem5" disabled>
                <Checkbox>Item Delivered</Checkbox>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="item2" title="Service Type">
              <Menu.Item key="subitem1" disabled>
                <Checkbox>All</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2" disabled>
                <Checkbox>In house</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem3" disabled>
                <Checkbox>Onsite</Checkbox>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        );

      case "3":
        return (
          <Menu mode="horizontal">
            <Menu.SubMenu key="item1" title="Status">
              <Menu.Item key="subitem1" disabled>
                <Checkbox
                  checked={tab3FilterStatus.length === 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterStatus([]);
                    } else {
                      setTab3FilterStatus([]);
                    }
                  }}
                >
                  All
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2" disabled>
                <Checkbox
                  checked={tab3FilterStatus.includes("paid")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterStatus([
                        ...tab3FilterStatus,
                        "paid",
                      ]);
                    } else {
                      setTab3FilterStatus(
                        tab3FilterStatus.filter(
                          (el) => el !== "paid"
                        )
                      );
                    }
                  }}
                >
                  Paid
                </Checkbox>
              </Menu.Item>
              {/* <Menu.Item key="subitem3">
                <Checkbox
                  checked={tab3FilterStatus.includes("unpaid")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterStatus([
                        ...tab1FilterStatus,
                        "unpaid",
                      ]);
                    } else {
                      setTab3FilterStatus(
                        tab3FilterStatus.filter((el) => el !== "unpaid")
                      );
                    }
                  }}
                >
                  Unpaid
                </Checkbox>
              </Menu.Item> */}
              <Menu.Item key="subitem4" disabled>
                <Checkbox
                  checked={tab3FilterStatus.includes("settle")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterStatus([
                        ...tab3FilterStatus,
                        "settle",
                      ]);
                    } else {
                      setTab3FilterStatus(
                        tab3FilterStatus.filter(
                          (el) => el !== "settle"
                        )
                      );
                    }
                  }}
                >
                  Settle
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem4" disabled>
                <Checkbox
                  checked={tab3FilterStatus.includes("partial")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterStatus([
                        ...tab3FilterStatus,
                        "partial",
                      ]);
                    } else {
                      setTab3FilterStatus(
                        tab3FilterStatus.filter(
                          (el) => el !== "partial"
                        )
                      );
                    }
                  }}
                >
                  Partial
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem5" disabled>
                <Checkbox
                  checked={tab3FilterStatus.includes("overdue")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterStatus([
                        ...tab3FilterStatus,
                        "overdue",
                      ]);
                    } else {
                      setTab3FilterStatus(
                        tab3FilterStatus.filter(
                          (el) => el !== "overdue"
                        )
                      );
                    }
                  }}
                >
                  Overdue
                </Checkbox>
              </Menu.Item>

            </Menu.SubMenu>
            <Menu.SubMenu key="item2" title="Payment Type">
              <Menu.Item key="subitem1" disabled>
                <Checkbox
                  checked={tab3FilterPaymentType.length === 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterPaymentType([]);
                    } else {
                      setTab3FilterPaymentType([]);
                    }
                  }}
                >
                  All
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2" disabled>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterPaymentType([
                        ...tab3FilterPaymentType,
                        "Paylah",
                      ]);
                    } else {
                      setTab3FilterPaymentType(
                        tab3FilterPaymentType.filter((el) => el !== "Paylah")
                      );
                    }
                  }}
                  checked={
                    tab3FilterPaymentType.includes("Paylah") ? true : false
                  }
                >
                  PayLah
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem3" disabled>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterPaymentType([
                        ...tab3FilterPaymentType,
                        "Paynow",
                      ]);
                    } else {
                      setTab3FilterPaymentType(
                        tab3FilterPaymentType.filter((el) => el !== "Paynow")
                      );
                    }
                  }}
                  checked={
                    tab3FilterPaymentType.includes("Paynow") ? true : false
                  }
                >
                  PayNow
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem4">
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterPaymentType([
                        ...tab3FilterPaymentType,
                        "Cash on pickup",
                      ]);
                    } else {
                      setTab3FilterPaymentType(
                        tab3FilterPaymentType.filter((el) => el !== "Cash on pickup")
                      );
                    }
                  }}
                  checked={
                    tab3FilterPaymentType.includes("Cash on pickup") ? true : false
                  }
                >
                  Cash On Pickup
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem5">
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab3FilterPaymentType([
                        ...tab3FilterPaymentType,
                        "Cheque on pickup",
                      ]);
                    } else {
                      setTab3FilterPaymentType(
                        tab3FilterPaymentType.filter((el) => el !== "Cheque on pickup")
                      );
                    }
                  }}
                  checked={
                    tab3FilterPaymentType.includes("Cheque on pickup") ? true : false
                  }
                >
                  Cheque On Pickup
                </Checkbox>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        );

      case "4":
        return (
          <Menu mode="horizontal">
            <Menu.SubMenu key="item1" title="Status">
              <Menu.Item key="subitem1">
                <Checkbox
                  checked={tab4FilterStatus.length === 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab4FilterStatus([]);
                    } else {
                      setTab4FilterStatus([]);
                    }
                  }}
                >
                  All
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem2">
                <Checkbox
                  checked={tab4FilterStatus.includes("Open") ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab4FilterStatus([
                        ...tab4FilterStatus,
                        "Open",
                      ]);
                    } else {
                      setTab4FilterStatus(
                        tab4FilterStatus.filter((el) => el !== "Open")
                      );
                    }
                  }}
                >Open</Checkbox>
              </Menu.Item>
              <Menu.Item key="subitem3">
                <Checkbox
                  checked={tab4FilterStatus.includes("Closed") ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTab4FilterStatus([...tab4FilterStatus, "Closed"]);
                    }
                    else {
                      setTab4FilterStatus(
                        tab4FilterStatus.filter((el) => el !== "closed")
                      );
                    }
                  }}
                >Closed</Checkbox>
              </Menu.Item>

            </Menu.SubMenu>
          </Menu>
        )

      default:
        return;
    }
  };

  const operations = (
    <div>
      <Filter menu={functionForMenu}>
        <Button className="d-flex align-items-center ml-2">
          <Icon className="mr-2" component={FilterIcon} />
          Filters
        </Button>
      </Filter>
    </div>
  );



  return (
    <>
      {/* Heading */}

      <Row>
        <Col span={10}>
          <PageHeading
            icon={UserManagementIcon}
            title="Account Management / Account Details"
          />
        </Col>
        {/* <Col span={14}>
          <div className=" d-flex align-items-center justify-content-end ">
          <SearchBox />
          <Filter>
            <Button className="d-flex align-items-center ml-2">
            
              <Icon component={FilterIcon}/>
              Filters
            </Button>
          </Filter>
           
          </div>
        </Col> */}
      </Row>

      {/* {tabChange!=="4" &&  <div >
      <Filter menu={functionForMenu}>
            <Button className="d-flex align-items-center ml-2">
              <Icon className="mr-2" component={FilterIcon} />
              Filters
            </Button>
          </Filter>
          </div>
} */}
      <Tabs
        tabBarExtraContent={tabChange !== "5" && operations}
        defaultActiveKey="1"
        className="tabs_centered"
        activeKey={tabChange}
        onChange={(val) => setTabChange(val)}
      >
        <Tabs.TabPane tab="Items & Services" key="1">
          <TabContent
            userData={userData}
            tab1FilterStatus={tab1FilterStatus}
            tab1FilterServiceType={tab1FilterServiceType}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Inquiry List" key="4">
          <TabContentInqurieList
            userData={userData}
            status={tab4FilterStatus}
            serviceType={tab2FilterServiceType}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Finances" key="3">
          <TabContentFinance
            status={tab3FilterStatus}
            paymentType={tab3FilterPaymentType}
            userData={userData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Address" key="5">
          <TabContentAddress userData={userData} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default AccountDetails;
