import { socket } from "services/Socket";

export const SendMessageSocket = (customerId, messageInput, type) => {
  console.log("i m in send message socket");
  console.log(customerId);
  console.log(messageInput);
  console.log(type);
  // console.log(localStorage)
  // const userID = localStorage.getItem('hrmsuserid');
  let userID = localStorage.getItem('hrmsuserid');
  console.log("___"+userID);
  console.log(localStorage.getItem('hrmsuserid'))
  if (!type) {
    socket.emit("message", {
      user_id: userID? userID : null,
      // user_id: null,
      inquiry_id: customerId.id,
      chat_id: customerId.chat_room_id,
      chat_message: messageInput,
      type: "text",
      media_file:"",
      isAdmin: true,
    });
  } else {
    socket.emit("message", {
      user_id: userID? userID : null,
      // user_id: null,
      isAdmin: true,
      inquiry_id: customerId.id,
      chat_id: customerId.chat_room_id,
      chat_message:"",
      media_file: messageInput,
      type: type,
    });
  }
};
