import { message } from "antd"
import axios from "axios"

export const UploadApi = async (file) => {
    const form = new FormData()
    form.append("media_file",file)
    try{
        const res =await axios.post(`customers/upload-chats`,form)
        return res.data.data.location
    }
    catch(err){
        message.error(err.response.data.message[0].title)
    }
    
}