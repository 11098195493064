import React from "react";
// import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown/index";
import { Menu, message } from "antd";
import { DeleteIcon, EditIcon } from "assets/svg/ActionsSvg";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilePdfOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SubMenu from "antd/lib/menu/SubMenu";
import { PdfIcon } from "views/app-views/ItemsAndServices/svgIcons";
import axios from "axios";

const Actions = ({ id, type, customer_quotation_id, customer_invoice_id, customer_itemAndServices_id }) => {


  const downloadPdfHandlerForQuotation = async () => {
    message.loading("Downloading Pdf", 3);
    try {
      const response = await axios({
        url: `/customer-app/Download-quotation`,
        method: "post",
        data: {
          id: customer_quotation_id,
        },
        responseType: "blob",
      });

      const url = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", "file.pdf");
      // document.body.appendChild(link);
      window.open(url, '_blank');
      // document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }



  };


  const downloadPdfHandlerInvoice = async () => {
    message.loading("Downloading Pdf", 3);
    try {
      const response = await axios({
        url: `/customer-app/Download-invoice`,
        method: "post",
        data: {
          id: customer_invoice_id,
        },
        responseType: "blob",
      });


      const url = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", "file.pdf");
      // document.body.appendChild(link);
      window.open(url, '_blank');
      // document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }

    // window.open(pdfUrl , '_blank');
  };


  const downloadPdfHandlerPhysicalAssissment = async () => {
    message.loading("Downloading Physical Assessment Pdf", 3);
    try {
      const response = await axios({
        url: `/customer-app/Download-assessment`,
        method: "post",
        data: {
          id: id,
        },
        responseType: "blob",
      });

      const url = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", "file.pdf");
      // document.body.appendChild(link);
      window.open(url, '_blank');
      // document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  }


  if (type === "itemAndServices") {
    return (
      <EllipsisDropdown
        menu={
          <Menu>
            <Menu.Item>
              <Link
                to={`/app/items-and-services/invoices/view-items/item-details?itemId=${id}`}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div>
                    <EyeOutlined />
                  </div>
                  {"   "} <div>View Details</div>
                </div>
              </Link>
            </Menu.Item>
            <SubMenu icon={<FilePdfOutlined />} title="View Pdf">
              <Menu.Item>
                <div onClick={downloadPdfHandlerForQuotation}  >
                  View Quotation
                </div>
              </Menu.Item>
              <Menu.Item
                onClick={downloadPdfHandlerInvoice}
              >View Invoice</Menu.Item>
              <Menu.Item
                onClick={downloadPdfHandlerPhysicalAssissment}
              >View Assessment Details</Menu.Item>
            </SubMenu>
          </Menu>
        }
      />
    );
  } else if (type === "Finance") {
    return (
      <EllipsisDropdown
        menu={
          <Menu>
            <Menu.Item>
              <Link
                to={`/app/finance-management/invoices/send-invoice?invoiceId=${id}`}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div>
                    <EyeOutlined />
                  </div>
                  {"   "} <div>View Details</div>
                </div>
              </Link>
            </Menu.Item>
          </Menu>
        }
      />
    );
  } else {
    return (
      <EllipsisDropdown
        menu={
          <Menu>
            <Menu.Item>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <EditOutlined />
                </div>
                {"   "} <div>Edit</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <DeleteOutlined />
                </div>
                {"   "} <div>Delete</div>
              </div>
            </Menu.Item>
          </Menu>
        }
      />
    );
  }
};

export default Actions;
