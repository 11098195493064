import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { setEditingMode, setProfilePic } from "redux/actions";
import { Tabs, Modal, message } from "antd";
import { UserManagementPageIcon } from "assets/svg/icon";
import {
  BasicDetailsIcon,
  BasicDetailsActiveIcon,
  AddressDetailsActiveIcon,
  AddressDetaisIcon,
} from "assets/svg/TabsSvg";
import Icon from "@ant-design/icons";
import PageHeading from "components/shared-components/PageHeading/PageHeading";
import BasicDetailsAcc from "./BasicDetailsAcc/BasicDetailsAcc";
import AddressDetailsAcc from "./AddressDetails/AddressDetailsAcc";
import { clearForm } from "redux/actions";
import { CLEAR } from "redux/constants/CustomerForm";

const AddNewAccount = (props) => {
  const { isInquiry, onAddNewCustomer, type } = props;
  const [userData, setuserData] = useState(null);
  const [clearForm, setclearForm] = useState(null);
  console.log("type", type);
  const history = useHistory();

  const dispatch = useDispatch();

  const [currActiveKey, setCurrActiveKey] = useState("1");
  const [disable, setDisable] = useState([false, true]);

  const getData = async (id) => {
    let res = await axios.get(`/customers/get-customer/${id}`);
    setuserData(res.data.data);
    console.log(res.data.data);
  };
  // const [formState, setFormState] = useState({
  //   basicDetails: {
  //     fullname: null,
  //     email: null,
  //     phone: null,
  //     dob: null,
  //     gender: null,
  //     age_group: null,
  //   },
  //   addresses: [],
  //   isEditing: false,
  // });

  // console.log(formState);

  const backHandler = () => {
    setCurrActiveKey((prev) => {
      let num = +prev;

      if (num > 1) {
        num = num - 1;
      }
      return num.toString();
    });
  };

  const nextHandler = () => {
    // if (currKey === "6") {
    //   return;
    // }
    const arr = disable;
    arr[+currActiveKey] = false;
    setDisable(arr);
    console.log(disable);
    setCurrActiveKey((prev) => {
      let num = +prev;
      // console.log(num);

      if (num < 2) {
        num = num + 1;
      }

      return num.toString();
    });
  };

  const tabChangeHandler = (key) => {
    setCurrActiveKey(key);
  };

  // let customerForm;
  // if(getCustomerFormData){
  const customerForm = useSelector((state) => state.customerForm);
  // }

  const sendFormDataHandler = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    if (id) {
      updateFormDataHandler();
      return;
    }

    setclearForm(true);
    try {
      const dob = customerForm.basicDetails.dob ? moment(customerForm.basicDetails.dob).format("YYYY-MM-DD"):null;
      let formData = {
        ...customerForm.basicDetails,
        dob,
        profile_pic: customerForm.profilePic,
        addresses: customerForm.addresses,
      };

      console.log(formData);
      // console.log(customerForm);
      // if (formData.profile_pic==="") {
      //   message.error('please add profile picture');
      //   return
      // }
      const res = await axios.post(`/customers/add-new-customer`, formData);

      if (!res.data.success) {
        //give the message as coming from api
        console.log(res.data.message);
        message.error(res.data.message);
      }

      message.success("Customer created successfully");
      dispatch(setProfilePic(""));

      if (isInquiry) {
        onAddNewCustomer();
      } else {
        history.push("/app/account-management/account-list");
      }
    } catch (err) {
      message.error(err.response.data.message);
      // Modal.error({
      //   title: "ERROR",
      //   content: err.message,
      //   onOk() {
      //     history.push("/app/account-management/account-list");
      //   },
      // });
    } finally {
    }
  };

  const updateFormDataHandler = async () => {
    try {
      const dob = customerForm.basicDetails.dob ?  moment(customerForm.basicDetails.dob).format("YYYY-MM-DD"):null;

      let formData = {
        ...customerForm.basicDetails,
        dob,
        addresses: customerForm.addresses,
        profile_pic: customerForm.profilePic,
      };

      let id = customerForm.id === null ? userData.id : customerForm.id;

      const urlParams = new URLSearchParams(window.location.search);
      const idOriginal = urlParams.get("id");

      if (!id) {
        id = idOriginal;
      }

      console.log("formData", formData);
      console.log("formData", customerForm);

      const res = await axios.patch(
        `/customers/update-customer/${id}`,
        formData
      );
      // const res1 = await axios.post(
      //   `/customers/upload-customer-profile-pic/${id}`,
      //   {profile_pic:customerForm.profilePic}
      // );
      // console.log(res1);

      if (!res.data.success) {
        throw new Error("Something went wrong...");
      }

      Modal.success({
        content: `Customer Updated Successfully (customer Id: ${id}`,
        onOk() {
          history.push("/app/account-management/account-list");
          dispatch(setProfilePic(""));
        },
      });
    } catch (err) {
      console.log(err.message);
      message.error("Something went wrong. Please try again after sometime...");
    } finally {
      // dispatch(setProfilePic(''));
      // dispatch(clearForm());
    }
  };
  
  useEffect(() => {
    if (type !== "edit") {
      dispatch(setProfilePic(""));
    }
    if (type === "edit") {
      dispatch(setEditingMode(true));
    } else {
      dispatch(setEditingMode(false));
    }

    const urlParams = new URLSearchParams(window.location.search);
    console.log("aaaaaa", window.location.search);
    console.log(urlParams);
    const id = urlParams.get("id");

    if (id) {
      getData(id);
    }
  }, []);

  return (
    <React.Fragment>
      {!isInquiry && (
        <PageHeading
          title="User Management / Add New Account"
          svg={UserManagementPageIcon}
        />
      )}
      <div className="removeAstrick">
        <Tabs activeKey={currActiveKey} onChange={tabChangeHandler}>
          <Tabs.TabPane
            tab={
              <span className="d-flex align-items-center">
                <Icon
                  component={
                    currActiveKey === "1"
                      ? BasicDetailsActiveIcon
                      : BasicDetailsIcon
                  }
                />
                Basic Details
              </span>
            }
            key="1"
            disabled={disable[0]}
          >
            <BasicDetailsAcc
              clearForm={clearForm}
              userData={userData === null ? "" : userData}
              type={type}
              onNext={nextHandler}
              isInquiry={isInquiry}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span className="d-flex align-items-center">
                <Icon
                  component={
                    currActiveKey === "2"
                      ? AddressDetailsActiveIcon
                      : AddressDetaisIcon
                  }
                />
                Address Details
              </span>
            }
            key="2"
            disabled={disable[1]}
          >
            <AddressDetailsAcc
              userData={userData === null ? "" : userData}
              onBack={backHandler}
              sendFormData={sendFormDataHandler}
              updateFormData={updateFormDataHandler}
              type={type}
              isInquiry={isInquiry}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </React.Fragment>
  );
};

export default AddNewAccount;
