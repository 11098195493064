import { UPDATE_GST } from "redux/constants/Gst"


const Gst = (state = null , action) => {
  switch(action.type){
    case UPDATE_GST:
      return {
        gst: action.gst
      }

    default: 
      return state
  }
}

export default Gst;