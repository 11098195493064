import React from "react";
import ChartWidget from "components/shared-components/ChartWidget";
import axios from "axios";
import { useState } from "react";
import "./Chart.css";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { filter } from "lodash";
import { useEffect } from "react";

const Chart = () => {
  const [revenueByFilter, setRevenueByFilter] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  const [revenueData, setRevenueData] = useState(null);

  const [months, setMonths] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
  ]);

  const revenueDataByFilter = async () => {
    var filterData = {
      filter: selectedYear,
    }

    try {
      const response = await axios.post('/dashboard/get-revenue-graph-data', filterData);
      // console.log("revenue by filter:", response.data);
      const newData = response.data.data;

      setSelectedYear(response.data.default_current_year);
      // oldTotalRevenue[0].data = newData.totalRevenue[0].data || [2, 1, 4, 5, 8, 26, 21, 20, 6, 8, 15, 10];

      const random2Digit = Math.floor(Math.random() * 100);

      let finalData = newData.totalRevenue[0].data || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      finalData = finalData.map((item) => {
        if (item == null) {
          return 0;
        } else {
          return item;
        }
      });

      const revenueDataNew = {
        totalRevenue: [
          {
            name: "Revenue Collection",
            data: finalData,
          },
        ],
        months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };

      console.log("revenue data new:", revenueDataNew);
      setRevenueData(revenueDataNew);
    } catch (error) {
      console.error('Error fetching finance data:', error);
    }
  };

  useEffect(() => {
    console.log("selected year:", selectedYear);
    revenueDataByFilter();
  }, [selectedYear]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      {revenueData ? <ChartWidget
        title={
          <div>
            <p className="m-0">Revenue Collection</p>
            <h2 className="m-0">S${revenueByFilter.data}</h2>
            <h5 className="m-0">Total Revenue</h5>
          </div>
        }
        series={revenueData.totalRevenue}
        xAxis={revenueData.months}
        height={600}
        extra={
          <Select
            placeholder="Year"
            onChange={handleYearChange}
            // value={selectedYear}
            defaultValue={selectedYear}
          >
            <Select.Option key="2024">2024</Select.Option>
            <Select.Option key="2023">2023</Select.Option>
            <Select.Option key="2022">2022</Select.Option>
            <Select.Option key="2021">2021</Select.Option>
          </Select>
        }
      /> : null}
    </>);
};

export default Chart;
