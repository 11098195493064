import React, { useState, useEffect } from "react";
// import Message from "assets/svg/Message.svg";
import PageHeading from "components/shared-components/PageHeading/PageHeading";
// import ChatLeft from './ChatLeft/ChatLeft';
import ChatRight from "./ChatRight/ChatRight";
import "./ChatLayout.css";
// import ChatLeftNew from './ChatLeftNew/ChatLeftNew';
import Inquiries from "./ChatLeftNew/Inquiries";
import AllInBox from "./ChatLeftNew/AllInBox";
import { Card } from "antd";
import axios from "axios";
import { socket } from "services/Socket";
import NavNotification from "components/layout-components/NavNotification";
import { useNotification } from '../../../../NotificationContext.js';

function ChatLayout() {
  const [inboxType, setInboxType] = useState("All");
  const [inquiries, setInquiries] = useState([]);
  const [oneUser, setOneUser] = useState({});
  const [customerId, setCustomerId] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [notifiData, setNotifiData] = useState([]);


  const { notificationData, customersId, userName } = useNotification();


  useEffect(() => {
    setNotifiData(notificationData)
    setCustomerId(customersId)

  }, [notificationData, customersId]);

  useEffect(() => {
    console.log("working");
    const onConnect = () => {
      console.log(socket.connected); // true
    }
    const onDisconnect = () => {
      console.log(socket.connected); // false
    }
    const onJoinEvent = (data) => {
      console.log(data);
      console.log("join event");
    }
    const onMessageEvent = (data) => {
      console.log(data);
      console.log("message event");
    }
    socket.on("connect", onConnect);
    socket.on("join", onJoinEvent);
    socket.on("message", onMessageEvent)
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('join', onJoinEvent);
      socket.off('message', onMessageEvent);
      socket.disconnect();
    }
  }, []);
  const getChatList = async (type) => {
    if (type === "Assigned") {
      const response = await axios.get(
        `/inquiry/get-all-inquires-Assigned`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response.data.data);
      //set inquiries empty
      const data = response.data.data.filter((item) => {
        return item.chat_room_id !== null
      })
      setInquiries(data);
    }
    if (type === "Unassigned") {
      const response = await axios.get(
        `/inquiry/get-all-inquires-Unassigned`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response.data.data);
      const data = response.data.data.filter((item) => {
        return item.chat_room_id !== null
      })
      setInquiries(data);
    }
    if (type === "Whatsapp") {
      const staticData = [
        {
          User_Name: "Pawan2",
          customer_profile_pic:
            "https://ops-hrms-bucket-uploads.s3.ap-southeast-1.amazonaws.com/customer_profile_pic/2023/5/image%20%2810%29-1685443332436.png",
          inquiry_type: "pickup support",
          inquiry_Channel: "whatsapp",
        },
      ]
      const response = await axios.get(
        `/inquiry/get-all-inquires-whatsapp`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = response.data.data.filter((item) => {
        return item.chat_room_id !== null
      })
      setInquiries(data);
    }
    if (type === "Email") {

      const response = await axios.get(
        `/inquiry/get-all-inquires-email`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = response.data.data.filter((item) => {
        return item.chat_room_id !== null
      })
      setInquiries(data);
    }
    if (type === "Closed") {
      const response = await axios.get(
        `/inquiry/get-all-inquires-closed`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = response.data.data.filter((item) => {
        return item.chat_room_id !== null
      })
      setInquiries(data);
    }
    if (type === "All") {
      fetchAllInquiries();

    }
  }
  async function fetchAllInquiries() {
    try {
      const response = await axios.get(
        `/inquiry/get-all-inquires?pageNumber=${pageNumber}&filters={"status":["open"]}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setPageSize(response.data.data.pagination.pageSize);
      setPageNumber(response.data.data.pagination.pageNumber);
      setTotalSize(response.data.data.pagination.totalSize);
      const data = response.data.data.finalData.filter((item) => {
        return item.chat_room_id !== null
      })
      setInquiries(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchAllInquiries();
  }, [pageNumber])




  // const dataNotifi = async () => {
  //   try {
  //     const res = await axios.get("/fcm/Inquires/get-all-unread-messages/132");

  //     setNotifiData(res.data.data);
  //   }
  //   catch (err) {
  //     console.error('Error fetching notification data:', err);
  //   }
  // }

  // useEffect(() => {
  //   // dataNotifi();

  //   // Fetch data initially when component mounts
  //   dataNotifi();

  //   // Call fetchData every 3 minutes
  //   const intervalId = setInterval(dataNotifi, 1 * 60 * 1000);

  //   // Clean up function to clear interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, [])

  return (
    <div>


      <div>
        <PageHeading
          // icon={Message}
          title="Chats"
        />
      </div>
      {/* <div className='chatLayout'> */}
      {/* <div className='chatLayoutLeft' >
                    <ChatLeft/>
                </div> */}
      {/* <div className='chatLayoutLeft'>
                    <ChatLeftNew/>
                </div> */}
      {/* <div className='allInBox'>
                    <AllInBox/>
                </div>
                <div className='inquiries'>
                    <Inquiries/>
                </div>
                <div className='chatLayoutRight' >
                    <ChatRight/>
                </div>
            </div> */}

      <Card >
        <Card.Grid style={{ width: "20%" }}>
          <AllInBox inquiries={inquiries} setInboxType={setInboxType} getChatList={getChatList} inboxType={inboxType} />
        </Card.Grid>
        <Card.Grid style={{ width: "30%", overflowX: "hidden" }}>
          <Inquiries inquiries={inquiries} inboxType={inboxType} setOneUser={setOneUser} notifiData={notifiData} customerId={customerId} setCustomerId={setCustomerId} pageSize={pageSize} pageNumber={pageNumber} totalSize={totalSize} setPageNumber={setPageNumber} />
        </Card.Grid>
        <Card.Grid style={{ width: "50%", padding: "0px", height: "530px", paddingBottom: "5px" }}>
          <ChatRight customerId={customerId} type={inboxType} userName={userName} />
        </Card.Grid>
      </Card>

    </div>
  )
}

export default ChatLayout