import { UPDATE_COUNTRIES } from "redux/constants/Countries";

const countries = (state = {countries: [] } , action) => {
  switch(action.type){
    case UPDATE_COUNTRIES:
      return {
        countries: action.loadedCountries
      }
    default:
      return state;
  }
}

export default countries;