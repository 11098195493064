import React, { lazy, Suspense , useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import Finance from "./Dashboard/Finance";
import UserManagement from "./UserManagement/UserManagement";
import AccountManagement from "./AccountManagement/AccountManagement";
import { onCountriesUpdate } from "redux/actions/Countries";
import { setCustomers } from "redux/actions/Customers";
import { onGstUpdate } from "redux/actions";

export const AppViews = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    
    const getAllCountries = async() => {
      
        const response = await axios.get(`/admin/get-all-countries`);

        // console.log(response.data.data);
        // setCountries(response.data.data);

        if (!response.data.success) {
          throw new Error(response.data.data || "Something went Wrong");
        }

        const loadedCountries = response.data.data;
        // console.log(loadedCountries);

        dispatch(onCountriesUpdate(loadedCountries));
      } 

    const getGst = async() => {
      try{
        const res = await axios.get(`/admin/get-tax-percentage`);
        
        const tax = res.data.data.tax_percentage;
        dispatch(onGstUpdate(tax));
      }
      catch(err){
        console.log(err);
      }
    }

      try{
        
        getAllCountries();
        getGst();
      }catch(err){
        console.log(err);
      }
    
  } , [])
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        
        
        <Route path={`${APP_PREFIX_PATH}/dashboard/finance`} component={lazy(() => import(`./Dashboard/Finance`))}/>
    
        <Route path={`${APP_PREFIX_PATH}/dashboard/sales`} component={lazy(() => import(`./Dashboard/Sales`))} />
        <Route path={`${APP_PREFIX_PATH}/dashboard/inventory`} component={lazy(() => import(`./Dashboard/Inventory`))} />
        <Route path={`${APP_PREFIX_PATH}/user-management`} component={lazy(() => import(`./UserManagement/UserManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/account-management`} component={lazy(() => import(`./AccountManagement/AccountManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/inquiry-management`} component={lazy(() => import(`./InquiryManagement/InquiryManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/items-and-services`} component={lazy(() => import(`./ItemsAndServices/ItemsAndServices`))} />

        {/* <Route path={`${APP_PREFIX_PATH}/send-invoice-pdf-2`} component={lazy(() => import(`./ItemsAndServices/Invoices/SendInvoicePdf`))} /> */}


        <Route path={`${APP_PREFIX_PATH}/inventory-management`} component={lazy(() => import(`./InventoryManagement/InventoryManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/delivery-management`} component={lazy(() => import(`./DeliveryManagement/DeliveryManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/finance-management`} component={lazy(() => import(`./FinanceManagement/FinanceManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./Reports/Reports`))} />
        <Route path={`${APP_PREFIX_PATH}/master-settings`} component={lazy(() => import(`./MasterSettings/MasterSettings`))} />

        <Route path={`${APP_PREFIX_PATH}/announcements`} component={lazy(() => import(`./Announcements/Announcements`))} />

        <Route path={`${APP_PREFIX_PATH}/add-new-announcement`} component={lazy(() => import(`./Announcements/AddNew/AddNewAnnouncement`))} />

        <Route path={`${APP_PREFIX_PATH}/edit-announcement/:id`} component={lazy(() => import(`./Announcements/AddNew/EditAnnouncement`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/contact-details`} component={lazy(() => import(`./Cms/ContactDetails/ContactDetails`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/add-contact-details`} component={lazy(() => import(`./Cms/ContactDetails/AddContactDetails`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/edit-contact-details`} component={lazy(() => import(`./Cms/ContactDetails/EditContactDetails`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/terms-conditions`} component={lazy(() => import(`./Cms/TermsConditions/TermsConditions`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/edit-terms-conditions/:id`} component={lazy(() => import(`./Cms/TermsConditions/EditTermsConditions`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/privacy-policy`} component={lazy(() => import(`./Cms/PrivacyPolicy/PrivacyPolicy`))} />

        <Route path={`${APP_PREFIX_PATH}/cms/edit-privacy-policy`} component={lazy(() => import(`./Cms/PrivacyPolicy/EditPrivacyPolicy`))} />

        



        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard/finance`} />
         
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);