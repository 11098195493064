import {
  BASIC_DETAILS,
  ADDRESSES,
  SET_EDITING_MODE,
  CLEAR,
  SET_ID,
  SET_PROFILE_PIC,
} from "redux/constants/CustomerForm";

export const updateBasicDetails = (basicDetails) => {
  return{
    type: BASIC_DETAILS,
    basicDetails,
  }
}

export const addAddress = (addresses) => {
  return{
    type: ADDRESSES,
    addresses,
  }
}

export const setId = (id) => {
  return{
    type: SET_ID,
    id,
  }
}

export const setEditingMode = (editMode) => {
  return{
    type: SET_EDITING_MODE,
    editMode,
  }
}

export const setProfilePic = (profilePic) => {
  return{
    type: SET_PROFILE_PIC,
    profilePic,
  }
}

export const clearForm = () => {
  return {
    type: CLEAR,
  }
}