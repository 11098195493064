import React, { useState , useContext, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
  Modal
} from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const { TextArea } = Input;




const Form1 = () => {

  // const [countries , setCountries] = useState([]);

  // useEffect(() => {
  //   const getAllCountries = async() => {
  //     try{
  //       const response = await axios.get(`/admin/get-all-countries`);

  //       // console.log(response.data.data);
  //       setCountries(response.data.data);
  //     } catch (err) {
  //       Modal.error({
  //         title: "Something went wrong",
  //         content: err.message
  //       })
  //     }
  //   }
  //   getAllCountries();
  // } , [])

  const countries = useSelector(state => state.countries.countries);
  // console.log(countries);


  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

    
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item label="Block Number" name="blockNumber">
            <Input />
          </Form.Item>
          <Form.Item label="Level Number" name="levelNumber">
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Postal Code" name="postalCode">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Street Number" name="streetNumber">
            <Input />
          </Form.Item>
          <Form.Item
            label="Unit Number"
            name="unitNumber"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Country" name="country_id">
          <Select>
              {countries.map((country) => {
                return (
                  <Select.Option value={country.id}>{country.name}</Select.Option>
                );
              })}
              
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default () => <Form1 />;
