import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAddress, setProfilePic } from "redux/actions";

import { Card, Form, Button, Modal, message } from "antd";

import Form1 from "./Form1";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
// import "./AddressDetailsAcc.css";

const AddressDetailsAcc = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const edittype = urlParams.get("type");
  const {
    isInquiry,
    userData,
    onBack,
    sendFormData,
    updateFormData,
    addAddressToExistingCustomerHandler,
    type,
    receiveValue,
  } = props;

  console.log("userData", userData);

  const [onComplete, setOnComplete] = useState(false);

  const [form] = Form.useForm();
  const [pincode, setPincode] = useState(false);
  console.log(pincode);

  const history = useHistory();
  const dispatch = useDispatch();
  const { isEditing } = useSelector((state) => state.customerForm);

  // console.log(customerForm);

  const onFinishHandler = (e) => {
    //  true;
    
    let check = false;
   e.address?.forEach((item) => {
      if (item.pin_code && !pincode) {
        check = true
      }
      if(item.pin_code===""){
        item.pin_code = null
      }
      if(item.street_name===""){
        item.street_name = null
      }
      if(item.unit_no===""){
        item.unit_no = null
      
      }
      if(item.block_no===""){
        item.block_no = null
      
      }
      if(item.level_no===""){
        item.level_no = null
      
      
      }
      if(item.country_id===""){
        item.country_id = null
      
      
      }
      
    })
    if(check){
      message.warn("Please input the correct Postal Code");
      return;
    }

    // if (e.address[0]?.pin_code && !pincode) {
    //   message.warn("Please input the correct Postal Code");
    //   return;
    // }

    let address = [];
    if (pincode) {
      address = [...(e.address || [])];
    }

    console.log("onFinishHandler address FORM1", address);

    dispatch(addAddress(address));

    Modal.confirm({
      title: "Create Customer",
      content: "Confirm to create the customer",
      onOk() {
        setOnComplete(true);

        if (isInquiry) {
          form.resetFields();
        }
      },
    });
    // sendFormData();
  };

  if (onComplete) {
    if (edittype === "edit" || isEditing) {
      updateFormData();
    } else sendFormData();

    setOnComplete(false);
  }
  useEffect(() => {
    console.log("userData ==>> ", userData);

    if (userData) {
      // dispatch(setProfilePic(userData.profile_pic));
      // form.setFieldsValue({
      //   street_name: userData.addresses[0].street_name,
      //   unit_no: userData.addresses[0].unit_no,
      //   block_no: userData.addresses[0].block_no,
      //   country_id: userData.addresses[0].country_id,
      //   level_no: userData.addresses[0].level_no,
      //   pin_code: userData.addresses[0].pin_code,
      // });
      console.log("userData.addresses", userData.addresses);

      form.setFieldsValue({
        address: userData.addresses,
      });
      console.log("form", form.getFieldValue());
    }
  }, [userData]);

  const receivePincodeFromChild = (pincode) => {
    setPincode(pincode); // Update pincode in parent component
    receiveValue(pincode)
    console.log("Address" + pincode)
  };

  return (
    <div>
      <Form
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="vertical"
        // onValuesChange={onFormLayoutChange}
        form={form}
        onFinish={addAddressToExistingCustomerHandler || onFinishHandler}
      >
        <Card className="mt-3">
          <h4 className="mb-3">Address</h4>
          <Form1
            type={type}
            form={form}
            // setPincode={setPincode}
            receiveBooleanValue={receivePincodeFromChild}
            userData={userData}
          />
        </Card>

        <div className="d-flex justify-content-end actions">
          {!addAddressToExistingCustomerHandler && (
            <Button onClick={() => onBack()}>Back</Button>
          )}
          {isEditing && (
            <Button
              style={{ width: "135px" }}
              onClick={() => form.resetFields()}
            >
              Reset
            </Button>
          )}
          {!isEditing && (
            <Button onClick={() => form.resetFields()}>Clear All</Button>
          )}


          <Button type="primary" htmlType="submit">
            {edittype === "edit" || (type === "edit" && isEditing)
              ? "Update"
              : "Submit"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddressDetailsAcc;
