import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserManagementFormContext } from "context/UserManagementFormContext";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
} from "antd";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Form2 = () => {
  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const ctx = useContext(UserManagementFormContext);

  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const getAllRoles = async () => {
      try {
        const res = await axios.get(
          `/admin/get-all-roles`
        );
        setRoles(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getAllDepartments = async () => {
      try {
        const res = await axios.get(
          `/admin/get-all-departments`
        );
        setDepartments(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllRoles();
    getAllDepartments();
  }, []);

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Role"
            name="role"
            initialValue={ctx.editFormData?.role_id}
            required={[
              {
                required: true,
                message: "Please Select Role"

              }
            ]}
          >
            <Select>
              {roles.map((role) => {
                return (
                  <Select.Option value={role.id}>{role.name}</Select.Option>
                );
              })}
              {/* <Select.Option value="demo">Demo</Select.Option> */}
            </Select>
          </Form.Item>

          <Form.Item
            label="Joining Date"
            name="joiningDate"
            rules={[
              {
                required: true,
                message: "Please input your date of birth",
              },
            ]}
            initialValue={
              ctx.editFormData.joining_date
                ? moment(ctx.editFormData?.joining_date, "YYYY/MM/DD")
                : null
            }
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Department"
            name="department"
            rules={[
              {
                required: true,
                message: "Field Required",
              },
            ]}
            initialValue={ctx.editFormData?.department_id}
          >
            <Select>
              {departments.map((dept) => (
                <Select.Option value={dept.id}>{dept.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Confirmation Date"
            name="confirmationDate"
            rules={[
              {
                required: true,
                message: "Please input your date of birth",
              },
            ]}
            initialValue={
              ctx.editFormData.confirmation_date
                ? moment(ctx.editFormData?.confirmation_date, "YYYY/MM/DD")
                : null
            }
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default () => <Form2 />;
