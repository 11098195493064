import React from "react";

import { Row, Col, Card } from "antd";

import Profile from "./Profile";
import InquiriesAndServices from "./InquiriesAndServices";

const TabContent = ({userData, tab1FilterServiceType, tab1FilterStatus}) => {
  return (
    <Row gutter={20} className="d-flex">
      <Col span={6} style={{ flex: 1 }}>
        <Card style={{ height: 600 }} className="ScrollY">
          <Profile userData={userData===null ? "" : userData} url={userData===null ? "" : userData.profile_pic}/>
        </Card>
      </Col>
      <Col span={18} style={{ flex: 1 }}>
        <Card >
          <InquiriesAndServices tab1FilterServiceType={tab1FilterServiceType} tab1FilterStatus={tab1FilterStatus} />
        </Card>
      </Col>
    </Row>
  );
};

export default TabContent;
