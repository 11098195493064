import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Tabs } from 'antd';
import store from './redux/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Views from './views';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import axios from "axios";
import { BASE_URL } from 'services/API';
import { NotificationProvider } from 'NotificationContext.js';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  axios.defaults.baseURL = `${BASE_URL}/ops/v1/`;
  //axios.defaults.baseURL = 'http://localhost:8000/ops/v1/';

  const savedToken = localStorage.getItem('token');
  const savedUserId = localStorage.getItem('hrmsuserid');

  if (savedToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;
  }

  if (savedUserId) {
    axios.defaults.headers.common['hrmsuserid'] = savedUserId;
  }

  const getTokenFromURL = () => {
    console.log("URL:", window.location.href);
    const urlSearchParams = new URLSearchParams(window.location.search);
    console.log("URL:", window.location.href);
    console.log("URL:", urlSearchParams.get("token"));
    return urlSearchParams.get("token");
  };

  const token = getTokenFromURL();
  console.log("URL:", token);



  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('token', token);
    }

    if (token || savedToken) {
      const fetchData = async () => {
        try {
          const response = await axios.post("https://hc-api.reddotapps.com.sg/api/getLoggedInUserId", {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          axios.defaults.headers.common['hrmsuserid'] = response.data.user_id;
          localStorage.setItem('hrmsuserid', response.data.user_id);

          console.log('user_id', response.data.user_id);
        } catch (error) {
          console.error("Error while fetching data:", error);
        }
      };

      fetchData();
    }
  }, [savedToken, token]);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <NotificationProvider>
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </NotificationProvider>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
