export const conversion = (id, type, service_type) => {
  if (type === "quotation") {
    if (service_type === "In House") {
      return `HCI${id}-QTN`;
    } else if (service_type === "Onsite") {
      return `OSI${id}-QTN`;
    }
    else{
      return `${id}-QTN`;
    }
  } else if (type === "invoice") {
    if (service_type === "In House") {
      return `HCI${id}-INV`;
    } else if (service_type === "Onsite") {
      return `OSI${id}-INV`;
    }else{
      return `${id}-INV`;
    }
  } else if (type === "allItem") {
    if (service_type === "In House") {
      return `HCI${id}-ORD`;
    } else if (service_type === "Onsite") {
      return `OSI${id}-ORD`;
    }else{
      return `${id}-ORD`;
    }
  } else if (type === "inquiry") {
    return `HCI-${id}`;
  } else if (type === "payment") {
    if (service_type === "In House") {
      return `HCI${id}-PAY`;
    } else if (service_type === "Onsite") {
      return `OSI${id}-PAY`;
    }
    else{
      return `${id}-PAY`;
    }
  }else if(type==="customer"){
    return `HC${id}`;
  }
};
