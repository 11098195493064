import React, { useState } from "react";
import { useSelector } from "react-redux";
import Icon, { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// import { PlusIcon } from "../../../svgIcons";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Card,
  Typography,
  message,
  Col,
} from "antd";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "services/API";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

const Form1 = (props) => {
  const { type, form, userData,receiveBooleanValue } = props;

  const countries = useSelector((state) => state.countries.countries);
  console.log("userData FORM1", userData);

  const [addressList, setAddressList] = useState(
    userData?.addresses ? userData?.addresses : [{}]
  );

  const pincodeApi = async (val, index) => {
    await fetch(`${BASE_URL}/api/get-pincode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pin_code: val,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // const result = data.results[0];
        if (!data.results.length) {
          message.warn("Wrong PinCode");
          receiveBooleanValue(false);
          return;
        }
        else{
          message.success("Correct Pincode")
          receiveBooleanValue(true);
        }
        
        const result = data.results[0];

        console.log("result", result);

        const getFormValues = props.form.getFieldsValue();
        console.log("getFormValues", getFormValues);

        const { setFieldsValue } = props.form;

        const oldAddresses = getFormValues.address;
        console.log("oldAddresses", oldAddresses);

        const newAddresses = [...oldAddresses];
        console.log("newAddresses", newAddresses);

        newAddresses[index] = {
          ...newAddresses[index],
          street_name: result.ROAD_NAME,
          block_no: result.BLK_NO,
        };

        console.log("newAddresses", newAddresses);

        setFieldsValue({
          address: newAddresses,
        });
        setAddressList(newAddresses);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect(() => {
  //   const { setFieldsValue } = props.form;

  //   setFieldsValue({
  //     address: addressList,
  //   });
  // }, [addressList]);

  let getData;

  const functionForStreet = async (val, index) => {
    clearTimeout(getData);
    const { setFieldsValue } = props.form;
    if(val===""){
      const getFormValues = props.form.getFieldsValue();
      const oldAddresses = getFormValues.address;
      const newAddresses = [...oldAddresses];
      newAddresses[index] = {
        ...newAddresses[index],
        street_name: "",
        block_no:"",
      }
      setFieldsValue({
        address:newAddresses,
      });
      return;
    }
    getData = setTimeout(() => {
      pincodeApi(val, index);
    }, 2000);
  };

  const handleAddAddress = () => {
    setAddressList([...addressList, {}]);
  };


  const handleRemoveAddress = (indexToRemove) => {
    const oldAddress = addressList[indexToRemove];
    const { setFieldsValue } = props.form;
    if (oldAddress.id) {
      axios.delete(
        `/customers/delete-customer-address/${oldAddress.id}`,
      ).then((res) => {
        setAddressList(addressList.filter((_, index) => index !== indexToRemove));
        setFieldsValue({
          address: addressList.filter((_, index) => index !== indexToRemove),
        })
      })
    } else {
      console.log("addressList", addressList)
      setAddressList(addressList.filter((_, index) => index !== indexToRemove));
      setFieldsValue({
        address: addressList.filter((_, index) => index !== indexToRemove),
      })
    }
  };

  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  let { basicDetails, addresses, isEditing } = useSelector(
    (state) => state.customerForm
  );

  if (+addresses.length === 0) {
    isEditing = false;
  }

  const keyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
    if (e.target.value.length >= 6) {
      e.preventDefault();
    }
  };

  // console.log(countries);
  return (
    <>
      <Card className="mt-3 w-100">
        <Button
          block
          type="dashed"
          onClick={() => handleAddAddress()}
          className="d-flex align-items-center justify-content-center pt-4 pb-4"
        >
          <Icon />
          <Title level={2} className="mb-0" bold style={{ color: "#ED2939" }}>
            Add More Address
          </Title>
        </Button>
      </Card>

      {addressList.map((address, index) => (
        <div key={index}>
          <Row>
            <Col span={12}>
              {/* <Form.Item
                label="Company/Individual Name"
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
                initialValue={basicDetails.fullname ? basicDetails.fullname : null}
              >
                <Input />
              </Form.Item> */}

              <Form.Item
                label=""
                hidden
                name={["address", index, "id"]}
                initialValue={
                  type === "edit" && isEditing ? address.id : null
                }
              >
                {/* <Input /> */}
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    Postal Code
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </div>
                }
                name={["address", index, "pin_code"]}
                // rules={[
                //   { required: true, message: "Please Provide the postal code" },
                //   {
                //     pattern: /^\d{6}$/,
                //     message: "Postal code must be exactly 6 digits.",
                //   },
                // ]}
                initialValue={
                  type === "edit" && isEditing ? address.pin_code : null
                }
              >
                <Input
                  onKeyPress={keyPress}
                  onChange={(e) => functionForStreet(e.target.value, index)}
                />
              </Form.Item>

              <Form.Item
                label="Block Number"
                name={["address", index, "block_no"]}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() !== value) {
                        return Promise.reject(
                          "Leading spaces are not allowed."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                initialValue={
                  type === "edit" && isEditing ? address.block_no : null
                }
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Unit Number"
                type="number"
                name={["address", index, "unit_no"]}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() !== value) {
                        return Promise.reject(
                          "Leading spaces are not allowed."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                initialValue={
                  type === "edit" && isEditing && address.unit_no
                    ? address.unit_no
                    : null
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name={["address", index, "country_id"]}
                initialValue={
                  type === "edit" && isEditing && address.country_id
                    ? address.country_id
                    : null
                }
              >
                <Select>
                  {countries &&
                    countries.map((country) => {
                      return (
                        <Select.Option value={country.id}>
                          {country.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Street Name"
                name={["address", index, "street_name"]}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() !== value) {
                        return Promise.reject(
                          "Leading spaces are not allowed."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                initialValue={
                  type === "edit" && isEditing && address.street_name
                    ? address.street_name
                    : null
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Level Number"
                name={["address", index, "level_no"]}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() !== value) {
                        return Promise.reject(
                          "Leading spaces are not allowed."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                initialValue={
                  type === "edit" && isEditing ? address.level_no : null
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {index > 0 && (
            <Button
              className="btn btn-danger mb-2"
              style={{
                backgroundColor: "#ED2939",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveAddress(index)}
            >
              Delete Address
            </Button>
          )}

          <div
            className="d-flex align-items-center justify-content-center mb-3"
            style={{ backgroundColor: "#ececec", height: "1.5px" }}
          ></div>
        </div>
      ))}

      {/* <button onClick={handleAddAddress}>Add New Address</button> */}
    </>
  );
};
export default Form1;
