import React, { useState, useContext , useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Form, Row, Button, Switch, Input } from "antd";
import Modal from "components/UI/Modal";

import "./AppAccess.css";
import SuccessSubmit from "./SuccessSubmit";
import { UserManagementFormContext } from "context/UserManagementFormContext";

const fields = [
  {
    label: "Mobile Application",
    name: "mobileApplication",
  },
  {
    label: "Web Application",
    name: "webApplication",
  },
  {
    label: "Create Orders",
    name: "createOrders",
  },
  {
    label: "Claim Inquiries",
    name: "claimInquiries",
  },
];

const AppAccess = (props) => {

  const history = useHistory();
  const ctx = useContext(UserManagementFormContext);

  const {formData , setFormData} = props;

  const [showSubmitHandler, setShowSubmitHandler] = useState(false);

  const [val , setVal] = useState(false);
  const [firstVisit , setFirstVisit] = useState(true);

  const submitHandler = () => {
    
    setShowSubmitHandler((prev) => !prev);

    if(firstVisit){
      setFirstVisit(false);
      return;
    }

    history.push(`/app/user-management/user-list`);

  };

  
  const finishHandler = (e) => {
    // console.log(e);
    
    ctx.setData(e);
    setVal(true);
    


    
    // console.log(ctx.formData);
    
  };

  if(val === true){
    let val;
    if(ctx.isEditing){
       val = props.editFormData();
      // val = props.sendFormData();
    }
    else{
      val = props.sendFormData();
    }

    setVal(false);
    if(val === true) submitHandler();
    // else history.push(`/app/user-management/user-list`);
  }

  const submitModal = (
    <Modal onClose={submitHandler}>
      <SuccessSubmit onClose={submitHandler} isEditing={ctx.isEditing} />
    </Modal>
  );

  return (
    <>
      {showSubmitHandler && submitModal}
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 8, offset: 12 }}
        layout="horizontal"
        className="pl-3 pr-3"
        onFinish={finishHandler}
      >
        <Card>
          <Row>
            <Col span={12} className="ml-4">
              {/* <Card> */}
              <div className="d-flex justify-content-between header mb-3">
                <p>Type</p>
                <p>Access</p>
              </div>
              {/* </Card> */}
              {fields.map((field) => (
                <Form.Item label={field.label} name={field.name}>
                  <Switch />
                </Form.Item>
              ))}
            </Col>
          </Row>
        </Card>
        <div className="d-flex justify-content-end actions">
          <Button>Back</Button>
          <Button>Clear All</Button>
          <Button type="primary" htmlType="submit">
            {ctx.isEditing?"Update User" : "Submit"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AppAccess;
