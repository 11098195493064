import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
} from "antd";
import { useContext } from "react";
import { UserManagementFormContext } from "context/UserManagementFormContext";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const Form1 = () => {
  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const ctx = useContext(UserManagementFormContext);
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item label="Highest Qualification" name="highestQualification" initialValue={ctx.editFormData?.highest_qualifications}>
            <Select >
              <Select.Option value="graduate">Graduate</Select.Option>
              <Select.Option value="btech">B.Tech</Select.Option>
              <Select.Option value="masters">Masters</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="University" name="university" rules={[{required: true , message: "enter your university"}]} initialValue={_.toLower(ctx.editFormData?.school_name)}>
            <Select >
              <Select.Option value="oxford university">Oxford University</Select.Option>
              <Select.Option value="abc">ABC</Select.Option>
              <Select.Option value="xyz">XYZ</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: "Please input your date of birth",
              },
            ]}
            initialValue={ctx.editFormData.dob?moment(ctx.editFormData.dob , "YYYY/MM/DD") : null}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
            initialValue={ctx.editFormData?.fullname}
          >
            <Input  />
          </Form.Item>
          <Form.Item label="Field of Study" name="fieldOfStudy" initialValue={ctx.editFormData?.field_of_study}>
            <Select >
              <Select.Option value="mba">MBA</Select.Option>
              <Select.Option value="physics">Physics</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            rules={[
              {
                required: true,
                message: "Please input your date of birth",
              },
            ]}
            initialValue={ctx.editFormData.course_end_date?moment(ctx.editFormData.course_end_date , "YYYY/MM/DD") : null}
          >
            <DatePicker  />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default () => <Form1 />;
