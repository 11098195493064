import React, { useState } from "react";

import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setProfilePic, updateBasicDetails } from "redux/actions";

const UploadProfilePic = (props) => {
  const { onUpload, type } = props;

  const [loading , setLoading] = useState(false);

  const { customerForm } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();

  const basicDetails = customerForm.basicDetails;

  const beforeUpload = async (file) => {
    // Check if file is valid
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Promise.reject(false);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    // Save file to state
    // console.log(file);
    // onUpload(file);

    const formData = new FormData();
    console.log(file);

    formData.append("profile_pic", file);
    try {
      setLoading(true);
      const res = await axios.post(
        `/customers/upload-customer-profile-pic`,
        formData
      );
      console.log(res.data);
      setLoading(false);
      dispatch(setProfilePic(res.data.data));
      return res.data;
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message);
      setLoading(false);
      return Promise.reject(false);
    }

    // Return file
  };

  const changeHandler = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setLoading(false);
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getImage = async(url) => {
    try{
      const res = axios.get(url);

    }
    catch(err){
      console.log(err);
    }
  }

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      
    >
      {customerForm.profilePic ? (
        <img
          className="w-100 h-100"
          style={{ objectFit: "cover" }}
          src={customerForm.profilePic}
          alt="Profile Pic"
        />
      ) :((type==='edit' && basicDetails.profile_pic) ? <img
        className="w-100 h-100"
        style={{ objectFit: "cover" }}
        src={basicDetails.profile_pic}
        alt="Profile Pic"
      /> :
        uploadButton
      )}
    </Upload>
  );
};

export default UploadProfilePic;
