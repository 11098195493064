import axios from "axios";
import { useCallback, useState ,useRef, useEffect } from "react";

export const useAxiosRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(async (url, method = "get", data = null, headers = {}) => {
    setIsLoading(true);
    // console.log(data);
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);
    try {
      const res = await axios.request({
        url,
        method,
        data,
        headers,
        signal: httpAbortCtrl.signal,
      });

      activeHttpRequests.current = activeHttpRequests.current.filter(
        (reqCtrl) => reqCtrl !== httpAbortCtrl
      );

      if (!res.data.success) {
        throw new Error(res.data.data);
      }
      return res.data.data;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return {isLoading , error , sendRequest , clearError};
};
