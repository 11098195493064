import { SET_CUSTOMERS , CLEAR , DELETE_CUSTOMER } from "redux/constants/Customers";

const initialState = {
  allCustomers: [],
};

const Customers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        allCustomers: action.customers,
      };

    case DELETE_CUSTOMER:
      const id = action.customerId;
      return {
        ...state,
        allCustomers: state.allCustomers.filter(cust => cust.id !== id)
      }

    case CLEAR:
      return {
        allCustomers: [],
      };
    default:
      return state;
  }
};

export default Customers;
