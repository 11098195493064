import { createContext, useState } from "react";

export const UserManagementFormContext = createContext({
  // FormData: {
  //   staffId: null,
  //   fullName: null,
  //   emailId: null,
  //   phoneNumber: null,
  //   ageGroup: null,
  //   dateOfBith: null,
  //   gender: null,
  //   martialStatus: null,
  //   race: null,
  //   religion: null,
  //   role: null ,
  //   department: null ,
  //   joiningDate: null ,
  //   confirmationDate: null ,
  //   residencyStatus: null ,
  //   nationality: null,
  //   icNumber: null,
  //   workPermitNumber: null,
  //   typeOfWorkPermit: null,
  //   expiryDateWorkPermit: null,
  //   PassportNumber: null,
  //   expiryDatePassport: null
  // },
  formData: {},
  editFormData: {},
  isEditing: false,
  setData: () => {},
  setEditData: () => {},
  resetEditData: () => {}
});

export const UserManagementFormContextProvider = (props) => {
  const [formData, setFormData] = useState({});
  const [editFormData , setEditFormData] = useState({});
  const [isEditing , setIsEditing] = useState(false);

  const setData = (data) => {
    // console.log(formData);
    setFormData((prev) => {return {...prev , ...data}});
  };

  const  setEditData = (data) => {
    setEditFormData((prev) => {return {...prev , ...data}});
  }

  const resetEditData = () => {
    setEditFormData({});
  }

  const isEditingHandler = () => {
    setIsEditing(true);
  }

  const notEditingHandler = () => {
    setIsEditing(false);
  }

  return (
    <UserManagementFormContext.Provider
      value={{
        formData,
        editFormData,
        isEditing,
        setData,
        setEditData,
        resetEditData,
        onEditing: isEditingHandler,
        onNotEditing: notEditingHandler,
      }}
    >
      {props.children}
    </UserManagementFormContext.Provider>
  );
};
