import React, { useState } from "react";

import { Card, Typography, Button, Col, Row, Space, Select, Modal } from "antd";
import Icon from "@ant-design/icons";

import { CrossIcon } from "assets/svg/icon";
import PrimaryAccount from "./PrimaryAccount";
import SecondryAccount from "./SecondryAccount";

import { MergeDrawerIcon } from "assets/svg/icon";

import classes from "./MergeDrawer.module.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

const { Title, Text } = Typography;

const style = {
  background: "#0092ff",
  padding: "8px 0",
};

const MergeDrawer = (props) => {
  const { onClose, toggleModal, loadedCustomers } = props;

  const [primaryCustomerId, setPrimaryCustomerId] = useState(null);
  const [secondaryCustomerId, setSecondaryCustomerId] = useState(null);
  const [selectedPrimaryId, setSelectedPrimaryId] = useState(null);
  const [selectedSecondaryId, setSelectedSecondaryId] = useState(null);

  const history = useHistory();

  const customers = loadedCustomers.map((cust) => {
    return {
      id: cust.id,
      email: cust.email,
      fullname: cust.fullname,
    };
  });
  console.log(customers);

  const selectPrimaryCustomer = (
    <Select
      style={{ width: "100%" }}
      className="mb-3"
      placeholder="Select Primary Customer"
      onSelect={(value) => {
        setPrimaryCustomerId(value);
      }}
      value={primaryCustomerId}
      showSearch
      filterOption={(input, option) => {
       return option.children.props.children[1].props.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 || option.children.props.children[0].props.children.indexOf(input) >= 0
       // return (((option.children.props.children[0].props.children.indexOf(input)) || (option.children.props.children[1].props.children?.toLowerCase()?.indexOf(input.toLowerCase()))) >= 0 )
      }}
    >
      {customers
        .filter((cust) => cust.id != secondaryCustomerId)
        .map((customer) => (
          <Select.Option value={customer.id}>
            <Space size={20}>
              <Text>{`HC-${customer.id}`}</Text>
              <Text>{customer.fullname}</Text>
            </Space>
          </Select.Option>
        ))}
    </Select>
  );
  const selectSecondaryCustomer = (
    <Select
      style={{ width: "100%" }}
      className="mb-3"
      placeholder="Select Secondary Customer"
      onSelect={(value) => setSecondaryCustomerId(value)}
      value={secondaryCustomerId}
      showSearch
     filterOption={(input, option) => {
      return option.children.props.children[1].props.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 || option.children.props.children[0].props.children.indexOf(input) >= 0
      // return (((option.children.props.children[0].props.children.indexOf(input)) || (option.children.props.children[1].props.children?.toLowerCase()?.indexOf(input.toLowerCase()))) >= 0 )
     }}
    >
      {customers
        .filter((cust) => cust.id != primaryCustomerId)
        .map((customer) => (
          <Select.Option value={customer.id}>
            <Space size={20}>
              <Text>{`HC-${customer.id}`}</Text>
              <Text>{customer.fullname}</Text>
            </Space>
          </Select.Option>
        ))}
    </Select>
  );
  // console.log(selectPrimaryCustomer);

  const swipeHandler = () => {
    const primaryId = primaryCustomerId;
    const secondaryId = secondaryCustomerId;
    setPrimaryCustomerId(secondaryId);
    setSecondaryCustomerId(primaryId);
  };

  

  const onConfirmMerge = async () => {
    try {
      const res = await axios.post(
        `/customers/merge-customers`,
        { primary_acc_id: primaryCustomerId, secondary_acc_id: secondaryCustomerId }
      );

      if(!res.data.success){
        throw new Error("Something went wrong");
      }
      Modal.success({
        title: "Two accounts merged successfully !",
        content: `Account Id: ${secondaryCustomerId} is merged into Account Id: ${primaryCustomerId}. Please find merged profiles in account details.`,
        okText: "Go to Accounts",
        onOk(){
          history.push("account-list");
          history.go(0);
        }
      })
    } catch (err) {
      Modal.error({
        title: "ERROR",
        content: err.message,
        onOk(){
          history.push("account-list");
          history.go(0);
        }
      });
    } finally{
      
      
    }
  };

  const mergeHandler = () => {
    onClose();
    // toggleModal();
    const primaryCustomer = loadedCustomers.find((cust) => cust.id == primaryCustomerId);
    const secondaryCustomer = loadedCustomers.find((cust) => cust.id == secondaryCustomerId);

    const confirmModalContent = (
      (
        <div className="d-flex justify-content-between mt-4">
          <div className="w-50">
            <Title level={3}>Primary Account</Title>
            <Row>
              <Col span={10}>
                <Text>customer Id:</Text>
              </Col>
              <Col>
                <Text>{primaryCustomer.id}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text>customer name:</Text>
              </Col>
              <Col>
                <Text>{primaryCustomer.fullname}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text>customer email:</Text>
              </Col>
              <Col>
                <Text>{primaryCustomer.email}</Text>
              </Col>
            </Row>
          </div>
          <div className="w-50">
            <Title level={3}>Secondary Account</Title>
            <Row>
              <Col span={10}>
                <Text>customer Id:</Text>
              </Col>
              <Col>
                <Text>{secondaryCustomer.id}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text>customer name:</Text>
              </Col>
              <Col>
                <Text>{secondaryCustomer.fullname}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Text>customer email:</Text>
              </Col>
              <Col>
                <Text>{secondaryCustomer.email}</Text>
              </Col>
            </Row>
          </div>
          <div></div>
        </div>
      )
    )


    Modal.confirm({
      title: "The following accounts will be merged",
      content: confirmModalContent,
      width: 800,
      onOk: onConfirmMerge
    });
  };

  return (
    <>
      <Card style={{ borderRadius: "8px 8px 0 0" }}>
        <div className="d-flex justify-content-between pl-4 pr-4 w-100">
          <div>
            <Title level={3} style={{ color: "#0099A8" }}>
              Merge
            </Title>
            <Text strong>Merge two same account below.</Text>
          </div>
          <button className={`${classes.cross}`} onClick={onClose}>
            <Icon component={CrossIcon}></Icon>
          </button>
        </div>
      </Card>

      <div className="ml-5 mr-5">
        <Row>
          <Col span={10}>
            <Title level={3}>Primary Account</Title>
            {selectPrimaryCustomer}
            {primaryCustomerId && <PrimaryAccount customerId={primaryCustomerId} />}
          </Col>

          <Col span={4} className="d-flex justify-content-center align-items-center">
            {primaryCustomerId && secondaryCustomerId && (
              <Button onClick={swipeHandler}>
                <Icon component={MergeDrawerIcon} />
              </Button>
            )}
          </Col>

          <Col span={10}>
            <Title level={3}>Secondary Account</Title>
            {selectSecondaryCustomer}
            {secondaryCustomerId && <SecondryAccount customerId={secondaryCustomerId} />}
          </Col>
        </Row>
        {primaryCustomerId && secondaryCustomerId && (
          <div className={`d-flex justify-content-end ${classes.actions} mb-4`}>
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button className={`${classes.merge_btn}`} type="primary" onClick={mergeHandler}>
                Merge
              </Button>
            </Space>
          </div>
        )}
      </div>
    </>
  );
};

export default MergeDrawer;
