import React, { useState, useContext } from "react";
import axios from "axios";
import { Button, Modal } from "antd";
import { UserManagementFormContext } from "context/UserManagementFormContext";

import UserManagementIcon from "../../../../assets/svg/usermManagementPage.svg";
import PageHeading from "components/shared-components/PageHeading/PageHeading";
import SearchBox from "components/shared-components/SearchBox";
import filterIcon from "../../../../assets/svg/filterIcon.svg";
import exportIcon from "../../../../assets/svg/exportIcon.svg";
import plusIcon from "../../../../assets/svg/plus.svg";
import UserListTable from "./UserListTable";
// import Actions from "./Actions";
import Filter from "./Filter";
import MyModal from "components/UI/Modal";
import Export from "./Export-UserList";
import { Link, useRouteMatch } from "react-router-dom";

const UserList = () => {
  const [showExportOption, setShowExportOption] = useState(false);
  const [searchText, setSearchText] = useState("");

  const ctx = useContext(UserManagementFormContext);

  const exportHandler = async () => {
    setShowExportOption((prev) => !prev);
  };

    

  const confirmHandler = async() => {
    try {
      //  const res = await axios.get(`/users/export-user-list-csv/1`);

      const res = await axios({
        url: `/users/export-user-list-csv/1`, //your url
        method: "GET",
        responseType: "blob", // important
      });
      // create file link in browser's memory
      const href = URL.createObjectURL(res.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      let i = i++;
      link.setAttribute("download", "sample.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      exportHandler();
    } catch (err) {
      console.log(err.message);
    }
  }

  const [error, setError] = useState(null);

  const exportModal = (
    <MyModal onclose={exportHandler}>
      <Export onClose={exportHandler} onConfirm={confirmHandler} />
    </MyModal>
  );
  return (
    <React.Fragment>
      {showExportOption && exportModal}
      {/* Heading */}
      <div>
        <PageHeading
          icon={UserManagementIcon}
          title="User Management / User List"
        />
      </div>

      {/* buttons */}
      <div className="d-flex justify-content-between mb-3">
        <div className=" d-flex align-items-center justify-content-between">
          <SearchBox
            onSearch={(text) => setSearchText(text)}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Filter>
            <Button className="d-flex align-items-center ml-2">
              <img className="mr-2" src={filterIcon} alt="filterIcon"></img>
              Filters
            </Button>
          </Filter>

          <Button
            className="d-flex align-items-center ml-2"
            onClick={exportHandler}
          >
            <img className="mr-2" src={exportIcon} alt="exportIcon"></img>Export
          </Button>
        </div>
        <div>
          <Link to="/app/user-management/add-new-staff">
            <Button
              className="d-flex align-items-center"
              type="primary"
              size="large"
              onClick={() => {
                ctx.resetEditData();
                ctx.onNotEditing();
              }}
            >
              <img className="mr-2" src={plusIcon} alt="plusIcon"></img>
              Add new
            </Button>
          </Link>
        </div>
      </div>

      {/* table */}
      <div>
        <UserListTable setError={setError} searchText={searchText} />
      </div>
    </React.Fragment>
  );
};

export default UserList;
